import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { MomentModule } from '../../node_modules/ngx-moment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './@auth/auth.module';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import {NgbModalModule, NgbAccordionModule} from '@ng-bootstrap/ng-bootstrap';
import { RegistrationModule } from './registration/registration.module';
import { ModeCheckerComponent } from './mode-checker/mode-checker.component';
import { ComponentsModule } from './@components/components.module';
import { MarketingModule } from './marketing/marketing.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [AppComponent, ModeCheckerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatIconModule,
    AppRoutingModule,
    AuthModule.forRoot(),
    RegistrationModule.forRoot(),
    MarketingModule.forRoot(),
    CoreModule.forRoot(),
    NgbModalModule,
    NgbAccordionModule,
    MomentModule,
    ComponentsModule,
    NgSelectModule
  ],
  bootstrap: [AppComponent],
  providers: [],
})
export class AppModule {
}
