import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaymentsApi } from "../api/payment.api";
import { environment } from "../../../../../environments/environment";

@Injectable()
export class PaymentService {
  constructor(private api: PaymentsApi) {}

  removeAutomatedTopUp(): Observable<any> {
    return this.api.removeAutomatedTopUp();
  }

  getAutomatedTopUp(): Observable<any> {
    return this.api.getAutomatedTopUp();
  }

  getPayments(startDate: Date, endDate: Date): Observable<any> {
    return this.api.getPayments(startDate, endDate);
  }

  topUp(topup: any): Observable<any> {
    return this.api.topUp(topup);
  }

  getPaymentCards(): Observable<any> {
    return this.api.getPaymentCards();
  }

  exportPayments(startDate: Date, endDate: Date): any {
    return this.api.exportPayments(startDate, endDate);
  }

  downloadReceipt(receiptId: number): any {
    return this.api.downloadReceipt(receiptId, environment.franchiseCode);
  }
}
