import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpService } from './api/http.service';
import { NbAuthModule } from '@nebular/auth';
import { ResellerCodeInterceptor } from './reseller-code.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

const API = [HttpService];
@NgModule({
  imports: [CommonModule, NbAuthModule],
})

export class CommonBackendModule {
  static forRoot(): ModuleWithProviders<CommonBackendModule> {
    return {
      ngModule: CommonBackendModule,
      providers: [
        ...API,
        { provide: HTTP_INTERCEPTORS, useClass: ResellerCodeInterceptor, multi: true }
      ],
    };
  }
}
