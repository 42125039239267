

import { NgModule, ModuleWithProviders } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpRequest } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  NbAuthJWTInterceptor,
  NbAuthModule,
  NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
} from '@nebular/auth';
import { AuthInterceptor } from './auth.interceptor';
import { AuthGuard } from './auth.guard';
import { NbSecurityModule } from '@nebular/security';

import {
  R247LoginComponent,
  R247AuthComponent,
  NgxAuthBlockComponent,
  R247LogoutComponent,
  //NgxRequestPasswordComponent,
  //NgxResetPasswordComponent,
} from './components';

import { AuthRoutingModule } from './auth-routing.module';
import { ComponentsModule } from '../@components/components.module';
import { authOptions } from './auth.settings';
import { authSettings } from './access.settings';
import { HttpService } from '../@core/backend/common/api/http.service';
import { R247ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { R247ResetPasswordComponent } from './components/reset-password/reset-password.component';

const GUARDS = [AuthGuard];
const PIPES = [];//AuthPipe];
const COMPONENTS = [
  R247LoginComponent,
  R247AuthComponent,
  R247LogoutComponent,
  R247ForgotPasswordComponent,
  R247ResetPasswordComponent,
  NgxAuthBlockComponent,
];

export function filterInterceptorRequest(req: HttpRequest<any>): boolean {
  return ['/auth/login', '/auth/sign-up', '/auth/request-pass', '/auth/refresh-token']
    .some(url => req.url.includes(url));
}

@NgModule({
  declarations: [...PIPES, ...COMPONENTS],
  imports: [
    AuthRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    ComponentsModule,
    NbAuthModule.forRoot(authOptions),
  ],
  exports: [...PIPES],
  providers: [
    HttpService,
    NbSecurityModule.forRoot({
      accessControl: authSettings,
    }).providers,
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: filterInterceptorRequest },
        { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        ...GUARDS],
    };
  }
}
