<div class="row">
    <div class="col-xs-12 col-md-4">
      <div class="card mb-3">
        <div class="card-header">
          <h6 class="card-title">Never Miss A Business Opportunity</h6>
        </div>
        <div class="card-body">
          <p style="font-weight: 500">Let our receptionists take a message on your behalf and view it in our easy to use portal. Plus receive an email and/or a text with the message.</p>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-4">
      <div class="card mb-3">
        <div class="card-header">
          <h6 class="card-title">No Contract Term To Tie You In</h6>
        </div>
        <div class="card-body">
          <p style="font-weight: 500">Simply credit your account with your preferred amount and top up the account up as desired, or activate auto tops to top up automaticly when your balance drops below £15.</p>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-4">
      <div class="card mb-3">
        <div class="card-header">
          <h6 class="card-title">Unique Business Number</h6>
        </div>
        <div class="card-body">
          <p style="font-weight: 500;">Choose from any dial code in the country, or take advantage of a non-geographic or freephone number.</p>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-4">
      <div class="card mb-3">
        <div class="card-header">
          <h6 class="card-title">From Start To Live In Five Minutes</h6>
        </div>
        <div class="card-body">
          <p style="font-weight: 500;">Choose your number, tell us about your business and how you would like us to answer the phone, top up your account and pick up the phone to say hello to your new receptionist.</p>
        </div>
      </div>
    </div>
  </div>