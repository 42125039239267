<div class="row mb-2">
    <div class="col-12 text-center">
      <h4>You are minutes away from having your own receptionist. Select your new unique number from the list below:
      </h4>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <r247-number-chooser (numberConfirmed)="goToRegister($event)"></r247-number-chooser>
    </div>
  </div>