<div class="pt-0">
      <div class="my-1 px-2">
          <button (click)="showSearchAreaModal()" type="button" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
            <span>{{selectedPrefixDisplay}}</span>
            <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="height: 12px; width: 12px; margin-top: -2;">
              <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </span>
          </button>
      </div>
      <div class="my-1 px-2">
        <button (click)="showCategoriesModal()" type="button" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
          <span>{{category}}</span>
          <span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="height: 12px; width: 12px; margin-top: -2;">
            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        </span>
        </button>
    </div>
      <!-- <div class="my-1 px-2">
        <select class="form-control" [(ngModel)]="category" (change)="searchNumbers()">
          <option *ngFor="let category of categories" [value]="category">{{category}}</option>
        </select>
      </div> -->
      <div>
        <h2 class="px-2 py-4 text-center" *ngIf="searchingNumbers">Searching...</h2>
        <h2 class="px-2 py-4 text-center" *ngIf="!searchingNumbers && (!numbers ||(numbers && numbers.length === 0))">No Numbers Found</h2>
      <div *ngIf="!searchingNumbers && numbers?.length > 0" class="list-group list-group-flush numbers-list">
        <div
          class="list-group-item list-group-item-action phone-number-item" 
          *ngFor="let number of numbers"
          (click)="selectNumber(number)"
          [ngClass]="{'selected-number': number.prefix + ' ' + number.range + ' ' + number.number === selectedNumber}" >
          {{number.prefix}} {{number.range}} {{number.number}}
        </div>
      </div>
      <div class="d-flex justify-content-between mt-1 px-2">
        <button class="btn btn-primary" [attr.disabled]="currentPage <= 1 ? true : null" (click)="onPreviousPage()">Prev</button>
        <div class="mt-1">
          Page {{currentPage}} of {{totalPages}}
        </div>
        <button class="btn btn-primary" [attr.disabled]="currentPage >= this.totalPages ? true : null" (click)="onNextPage()">Next</button>
      </div>
      </div>
    </div>