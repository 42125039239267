

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReceptionApi } from '../api/reception.api';

@Injectable()
export class ReceptionService {
    constructor(private api: ReceptionApi) { }
        
    getConfig(): Observable<any> {
        return this.api.getConfig();
    }
}

