import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResellerApi } from "../api/reseller.api";

@Injectable()
export class ResellerService {
  constructor(private api: ResellerApi) {}

  // get(): Observable<any> {
  //   return this.api.get(this.currentResellerCode).pipe(
  //     map((config) => {
  //       return config;
  //     })
  //   );
  // }

  countClients(): Observable<number> {
    return this.api.countClients();
  }

  countNewClientsPerMonth(): Observable<any> {
    return this.api.countNewClientsPerMonth();
  }

  countTotalClientsPerMonth(): Observable<any> {
    return this.api.countTotalClientsPerMonth();
  }

  calculateRevenuPerClient(
    periodStart: Date,
    periodEnd: Date
  ): Observable<any> {
    return this.api.calculateRevenuePerClient(periodStart, periodEnd);
  }

  exportRevenuePerClient(periodStart: Date, periodEnd: Date) {
    return this.api.exportRevenuePerClient(periodStart, periodEnd);
  }

  calculateUnRealisedRevenue(): Observable<number> {
    return this.api.calculateUnRealisedRevenue();
  }

  calculateRevenuePerMonth(): Observable<any> {
    return this.api.calculateRevenuePerMonth();
  }
}
