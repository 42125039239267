import { ChangeDetectionStrategy, Component, } from '@angular/core';

@Component({
  selector: 'r247-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class R247FaqsComponent {
  constructor() {}
}