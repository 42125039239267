
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule } from '@nebular/auth';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  LayoutService,
  StateService,
} from './utils';

import { CommonBackendModule } from './backend/common/common-backend.module';
import { ReceptionistBackendModule } from './backend/receptionist/receptionist-backend.module';
import { PaymentValuesExtractor } from './utils/payment-values-extractor';
import { ExportService } from './backend/receptionist/services/export-service';
import { FileSaverModule } from 'ngx-filesaver';
import { AddressValuesExtractor } from './utils/address-values-extractor';
import { GraphDataBuilder } from './utils/graph-data-builder';

export const NB_CORE_PROVIDERS = [
  ...CommonBackendModule.forRoot().providers,
  ...ReceptionistBackendModule.forRoot().providers,
  PaymentValuesExtractor,
  AddressValuesExtractor,
  GraphDataBuilder,
  LayoutService,
  StateService,
];

const COMPONENTS = [];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    FileSaverModule
  ],
  exports: [
    NbAuthModule,
  ],
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
        ExportService
      ],
    };
  }
}
