

export const authSettings = {
  guest: {
  },
  user: {
    parent: 'guest',
      view: ['current-user', 'orders','top-up'],
      edit: ['current-user', 'orders'],
      client: [ 'current-balance','top-up'],
  },
  vendor: {
    parent: 'user',
      view: ['current-user', 'orders', 'users'],
      edit: ['current-user', 'orders', 'users'],
  },
  admin: {
    parent: 'vendor',
      view: ['current-user', 'vendors'],
      edit: ['current-user', 'orders'],
  },
};
