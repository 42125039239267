export class User {
  password = "";
  confirmPassword = "";
  fullName = "";
  email = "";
  isTermsAndConditionsAccepted = false;
}

export class Contact {
  email = "";
  name = "";
  phoneNo = "";
  isPrimary = false;
  sendSms = false;
  sendEmail = false;
}

export class Address {
  addressLine1 = "";
  addressLine2 = "";
  addressLine3 = "";
  postCode = "";
  city = "";
  county = "";
  country = "";
}
export class Reception {
  virtualNumberPrefix = "";
  virtualNumberRange = "";
  virtualNumberNumber = "";
  numberPlan = "";
  description = "";
  openingHours = "";
  phoneNo = "";
  website = "";
  email = "";
  contacts = [];
  shouldCollectCompanyName: boolean = false;
  greeting: "";
}

export class Client {
  name = "";
  isActive = true;
  isDeleted = false;
  phoneNumber = "";
  emailAddress = "";
  description = "";
  website = "";
  openingHours: "";
  companyAddressLine1: "";
  companyAddressLine2: "";
  companyAddressLine3: "";
  companyCity: "";
  companyCounty: "";
  companyCountry: "";
  companyPostCode: "";
  billingAddressLine1: "";
  billingAddressLine2: "";
  billingAddressLine3: "";
  billingCity: "";
  billingCounty: "";
  billingCountry: "";
  billingPostCode: "";
  receptions = [];
}

export class Payment {
  amount = 0;
  createAutoTopUp = false;
  cardId = 0;
  cardNumber = "";
  cardHolderName = "";
  cvc = "";
  expiryMonth = "";
  expiryYear = "";
  saveCardDetails = false;
  cardDescription = "";
}

export class signUpForm {
  franchiseCode = "";
  resellerId = 0;
  user = new User();
  client = new Client();
  payment = new Payment();
}

export class signUpResult {
  status = "";
  errorMessage = "";
  isRectifiableError = false;
  token = "";
}
