import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CommonBackendModule } from "../common/common-backend.module";
import { PhoneNumbersApi } from "./api/numbers.api";
import { PhoneNumbersService } from "./services/phone-number.service";
import { PaymentsApi } from "./api/payment.api";
import { ReceptionApi } from "./api/reception.api";
import { PaymentService } from "./services/payment-service";
import { ReceptionService } from "./services/reception-service";
import { ClientService } from "./services/client-service";
import { BalanceService } from "./services/balance-service";
import { SignUpApi } from "./api/sign-up-api";
import { AuthApi } from "./api/auth.api";
import { AuthService } from "./services/auth.service";
import { ReceptionistService } from "./services/receptionist-service";
import { ReceptionistApi } from "./api/receptionist-api";
import { ResellerService } from "./services/reseller-service";
import { ResellerApi } from "./api/reseller.api";
import { ExportApi } from "./api/export.api";
import { PasswordApi } from "./api/password.api";
import { PasswordService } from "./services/pasword-service";
import { PricingService } from "./services/pricing-service";
import { PricingApi } from "./api/pricing.api";
import { StatementsService } from "./services/statements-service";
import { FranchiseApi } from "./api/franchise.api";
import { FranchiseService } from "./services/franchise-service";

const API = [
  PhoneNumbersApi,
  PricingApi,
  SignUpApi,
  AuthApi,
  PaymentsApi,
  ReceptionApi,
  ReceptionistApi,
  ResellerApi,
  FranchiseApi,
  ExportApi,
  PasswordApi,
];

const SERVICES = [
  PhoneNumbersService,
  PaymentService,
  BalanceService,
  ReceptionService,
  ReceptionistService,
  ClientService,
  AuthService,
  ResellerService,
  FranchiseService,
  PhoneNumbersService,
  PricingService,
  PaymentService,
  ReceptionService,
  PasswordService,
  StatementsService,
];

@NgModule({
  imports: [CommonModule, CommonBackendModule],
  exports: [CommonBackendModule],
})
export class ReceptionistBackendModule {
  static forRoot(): ModuleWithProviders<ReceptionistBackendModule> {
    return {
      ngModule: ReceptionistBackendModule,
      providers: [...API, ...SERVICES],
    };
  }
}
