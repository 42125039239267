import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnInit,
  Input,
} from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "r247-search-bar",
  templateUrl: "./search-bar.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247SearchBarComponent implements OnInit {
  constructor() {}

  THIS_MONTH = "this_month";
  LAST_MONTH = "last_month";
  CUSTOM = "custom";

  selectedFilter = this.THIS_MONTH;

  startDate: NgbDate;
  endDate: NgbDate;

  @Input() isExporting = false;

  get exportCaption() {
    return this.isExporting ? "Exporting..." : "Export";
  }

  @Input() showStatementsLink = false;

  @Output() search = new EventEmitter();
  @Output() export = new EventEmitter();

  ngOnInit(): void {
    this.initialiseDates(this.selectedFilter);
  }

  onFilterSelectChange() {
    this.changeFilter(this.selectedFilter);
  }

  changeFilter(filter) {
    if (filter === this.CUSTOM) {
      this.initialiseDates(this.selectedFilter);
      this.selectedFilter = filter;

      return;
    }

    this.initialiseDates(filter);
    this.selectedFilter = filter;

    this.onSearch();
  }

  initialiseDates(filter) {
    var today = new Date();
    var month = today.getMonth();

    if (filter === this.THIS_MONTH) {
      month = today.getMonth() + 1;
    } else if (filter === this.LAST_MONTH) {
      today.setMonth(today.getMonth() - 1);
      month = today.getMonth() + 1;
    }

    this.startDate = new NgbDate(today.getFullYear(), month, 1);

    var monthEnd = new Date(today.getFullYear(), month, 0);
    this.endDate = new NgbDate(
      monthEnd.getFullYear(),
      month,
      monthEnd.getDate()
    );
  }

  onSearch() {
    this.search.emit(this.getSearchCriteria());
  }

  onExport() {
    this.export.emit(this.getSearchCriteria());
  }

  private getSearchCriteria() {
    return {
      periodStart: new Date(
        this.startDate.year,
        this.startDate.month - 1,
        this.startDate.day
      ),
      periodEnd: new Date(
        this.endDate.year,
        this.endDate.month - 1,
        this.endDate.day
      ),
    };
  }

  dateRangeChange(dateRange) {
    this.startDate = dateRange.fromDate;
    this.endDate = dateRange.toDate;
  }
}
