import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "r247-number-list-card",
  styleUrls: ["./number-list-card.component.scss"],
  templateUrl: "./number-list-card.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247NumberListCardComponent implements OnInit {
  constructor() {}

  @Input() prefix: string = "";
  @Input() categories: string[] = [];
  @Input() prefixes = [];

  @Input() selectedNumber = "";

  @Output() numberSelected = new EventEmitter();
  @Output() numbersSearched = new EventEmitter();

  category: string = "";

  @Input() numbers: [];
  @Input() searchingNumbers = true;

  ngOnInit(): void {
    this.category = this.categories[0];
  }

  onCategoryChange(value) {
    this.category = value;
    this.searchNumbers();
  }

  searchNumbers() {
    this.numbersSearched.emit({
      category: this.category,
      prefix: this.prefix,
    });
  }

  selectNumber(category, number) {
    this.selectedNumber = `${number.prefix} ${number.range} ${number.number}`;

    this.numberSelected.emit({
      category,
      prefix: number.prefix,
      range: number.range,
      number: number.number,
    });
  }

  searchPrefixes(term: string, prefix: any) {
    term = term.toLowerCase();
    return (
      prefix.code.toLowerCase().startsWith(term) ||
      prefix.area.toLowerCase().startsWith(term)
    );
  }
}
