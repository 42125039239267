import { Component, ChangeDetectionStrategy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';

@Component({
  selector: 'r247-top-up-input',
  templateUrl: './top-up-input.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})

export class R247TopUpInputComponent implements OnInit {
  constructor(private fb: FormBuilder) { }
  
    @Input() minimumTopUp = 40;
    @Input() topUpForm: FormGroup;
    @Output() amountChanged = new EventEmitter();

    topUpInputForm: FormGroup; 

  get isCustomAmount() { return this.topUpInputForm.get('amount').value === 'custom'; }
  get customAmount() { return this.topUpInputForm.get('customAmount'); }

  ngOnInit(): void {
    this.topUpInputForm = this.fb.group({
      amount: [this.minimumTopUp, [Validators.required]],
      customAmount: ['', Validators.min(this.minimumTopUp)],
    });
  }

  broadcastNewAmount() {
    console.log(this.customAmount);
    var selectedAmount = this.topUpInputForm.get('amount').value;

    this.amountChanged.emit(selectedAmount === 'custom' ? this.topUpInputForm.get('customAmount').value : selectedAmount);
  }
}