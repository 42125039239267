import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { signUpForm } from '../../../interfaces/common/signUp';
import { HttpService } from '../../common/api/http.service';

@Injectable()
export class SignUpApi {
  private readonly apiController: string = 'register';

  constructor(private api: HttpService) { }

  signUp(signUpForm:signUpForm):any {
    return this.api.post(`${this.apiController}`, signUpForm);
  }

  purchaseNumber(number: any) : any {
    return this.api.post(`${this.apiController}/purchase-number`, number);
  }
}
