import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { FranchiseApi } from "../api/franchise.api";

@Injectable()
export class FranchiseService {
  constructor(private api: FranchiseApi) {}

  get(): Observable<any> {
    return this.api.get(environment.franchiseCode).pipe(
      map((config) => {
        return config;
      })
    );
  }

  getFranchiseStyles(): Observable<any> {
    return this.api.getStyles(environment.franchiseCode);
  }

  calculateRevenuePerReseller(
    periodStart: Date,
    periodEnd: Date
  ): Observable<any> {
    return this.api.calculateRevenuePerReseller(periodStart, periodEnd);
  }

  topResellersByRevenue(periodStart: Date, periodEnd: Date): Observable<any> {
    return this.api.topResellersByRevenue(periodStart, periodEnd);
  }

  topResellersByClientCount(
    periodStart: Date,
    periodEnd: Date
  ): Observable<any> {
    return this.api.topResellersByClientCount(periodStart, periodEnd);
  }

  countClients(): Observable<any> {
    return this.api.countClients();
  }

  countResellers(): Observable<any> {
    return this.api.countResellers();
  }

  calculateRevenuePerMonth(): Observable<any> {
    return this.api.calculateRevenuePerMonth();
  }

  calculateUnRealisedRevenue(): Observable<number> {
    return this.api.calculateUnRealisedRevenue();
  }

  exportRevenuePerReseller(periodStart: Date, periodEnd: Date) {
    return this.api.exportRevenuePerReseller(periodStart, periodEnd);
  }
}
