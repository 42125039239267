import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../../common/api/http.service";
import { FranchiseService } from "../services/franchise-service";
import { environment } from "../../../../../environments/environment";

@Injectable()
export class PasswordApi {
  private readonly apiController: string = "password";

  constructor(
    private api: HttpService,
    private franchiseService: FranchiseService
  ) {}

  requestPasswordReset(email: string): Observable<boolean> {
    return this.api.post(`${this.apiController}/reset/request`, {
      email: email,
      franchiseCode: environment.franchiseCode,
    });
  }

  validateResetPasswordToken(token: string): Observable<boolean> {
    return this.api.post(`${this.apiController}/reset/validate`, {
      token: token,
      franchiseCode: environment.franchiseCode,
    });
  }

  resetPassword(passwordReset: any): Observable<any> {
    passwordReset.franchiseCode = environment.franchiseCode;

    return this.api.post(`${this.apiController}/reset`, passwordReset);
  }

  changePassword(changePassword: any): Observable<any> {
    return this.api.post(`${this.apiController}/change`, changePassword);
  }
}
