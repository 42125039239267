import { ChangeDetectionStrategy, Component, } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'r247-marketing-screen',
  styleUrls: ['./marketing-screen.component.scss'],
  templateUrl: './marketing-screen.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})

export class R247MarketingScreenComponent {
  constructor(protected router: Router) {}
    
    goToRegister(selectedNumber) {      
      return this.router.navigateByUrl(`/register/account?category=${selectedNumber.category}&prefix=${selectedNumber.prefix}&range=${selectedNumber.range}&number=${selectedNumber.number}`);
    }
}
