import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";

@Injectable()
export class TopUpFormBuilder {
  constructor(private fb: FormBuilder) {}

  private amount = 0;

  withAmount(amount: number): TopUpFormBuilder {
    this.amount = amount;

    return this;
  }

  private minimumAmount = 40;

  withMinimumAmount(minimumAmount: number): TopUpFormBuilder {
    this.minimumAmount = minimumAmount;

    return this;
  }

  private isAutoTopUp = false;

  enableAutoTopUp(): TopUpFormBuilder {
    this.isAutoTopUp = true;

    return this;
  }

  disableAutoTopUp(): TopUpFormBuilder {
    this.isAutoTopUp = false;

    return this;
  }

  create() {
    return this.fb.group({
      isAutoTopUp: [this.isAutoTopUp, []],
      amount: [
        this.amount,
        {
          validators: [Validators.min(this.minimumAmount), Validators.required],
          updateOn: "blur",
        },
      ],
    });
  }
}
