import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FranchiseService } from "../backend/receptionist/services/franchise-service";

@Injectable()
export class GraphDataBuilder {
  constructor(private franchiseService: FranchiseService) {}

  build(result: any): Observable<any> {
    return this.franchiseService.getFranchiseStyles().pipe(
      map((styles) => {
        return {
          labels: result.map((x: any) => x.name),
          datasets: [
            {
              backgroundColor: styles.primary, //'#e80c30',
              data: result.map((x: any) => x.value),
            },
          ],
        };
      })
    );
  }
}
