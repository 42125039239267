import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import {
  R247AuthComponent,
  R247LoginComponent,
  R247LogoutComponent,
  //NgxRequestPasswordComponent,
  //NgxResetPasswordComponent,
} from "./components";
import { R247ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { R247ResetPasswordComponent } from "./components/reset-password/reset-password.component";

const routes: Routes = [
  {
    path: "",
    component: R247AuthComponent,
    children: [
      {
        path: "login",
        component: R247LoginComponent,
      },
      {
        path: "logout",
        component: R247LogoutComponent,
      },
      {
        path: "password/forgot",
        component: R247ForgotPasswordComponent,
      },
      {
        path: "password/reset",
        component: R247ResetPasswordComponent,
      },
      {
        path: "",
        component: R247LoginComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
