import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { GraphDataBuilder } from '../../@core/utils/graph-data-builder';

@Component({
  selector: 'r247-graph',
  templateUrl: './graph-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})

export class R247GraphWrapperComponent implements OnInit {
    constructor(private graphDataBuilder: GraphDataBuilder) {}

    @Input() data = [];
    @Input() yAxisLabel = '';  
    @Input() formatTicks = (value, index, ticks) => value;
    @Input() stepSize = 1;

    graphData= {};
    options = {};

    ngOnInit(): void {

      this.graphDataBuilder.build(this.data).subscribe((graphData) => this.graphData = graphData);

      var max = Math.ceil(Math.max(...this.data.map((x:any) => x.value)) / 10) * 10;

      this.options = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.yAxisLabel,
              fontSize: 16,
              fontStyle: 'bold'
            },
            ticks: { 
              beginAtZero: true,
              callback: this.formatTicks,
              stepSize: this.stepSize,
              max: max > 0 ? max : this.stepSize > 10 ? this.stepSize : 10 
            }
          }]
        }
      };
    }
}
