export const STAGING_API_URL = "https://api-staging.callcentre.services";
export const PREPROD_API_URL = "https://api-preprod.callcentre.services";
export const PROD_API_URL = "https://api.callcentre.services";
export const DEV_API_URL = "https://localhost:5001"; // "http://10.1.5.50:5001";

export const JUNO_RESELLER_CODE = "Juno";
export const TWO_FOUR_SEVEN_RECEPTIONIST_RESELLER_CODE = "247REC";
export const KALLKWIK_RECEPTIONIST_RESELLER_CODE = "Kallkwik";
export const MAILBOXES_ETC_RESELLER_CODE = "mbe";
export const ACTION_COACH_RESELLER_CODE = "actioncoach";
