import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, } from '@angular/forms';

@Component({
  selector: 'r247-top-up-form',
  templateUrl: './top-up-form.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class R247TopUpFormComponent implements OnInit {
  constructor() {}

    @Input() allowRecurringTransaction = true;
    @Input() minimumTopUp = 40;
    @Input() topUpForm: FormGroup;
    @Output() autoTopUpToggled = new EventEmitter();

  ngOnInit(): void {
    this.amountChanged(this.minimumTopUp);
  }

  amountChanged(amount) {
    this.topUpForm.patchValue({ amount: amount });
  }
}