import { Component, ChangeDetectionStrategy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, } from '@angular/forms';
import { AddressFormBuilder } from '../../../@components/address-picker/address-form-builder';
import { CardDetailsFormBuilder } from '../../../@components/card-details-form/card-details-form-builder';
import { TopUpFormBuilder } from '../../../@components/top-up-form/top-up-form-builder';
import { Payment } from '../../../@core/interfaces/common/signUp';
import { AddressValuesExtractor } from '../../../@core/utils/address-values-extractor';
import { PaymentValuesExtractor } from '../../../@core/utils/payment-values-extractor';

@Component({
  selector: 'r247-register-payment-screen',
  templateUrl: './register-payment-screen.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})

export class R247RegisterPaymentScreenComponent implements OnInit {
  constructor(
    private topUpFb: TopUpFormBuilder, 
    private addressFb: AddressFormBuilder,
    private addressValuesExtractor: AddressValuesExtractor,
    private cardDetailsFb: CardDetailsFormBuilder,
    private paymentValuesExtractor: PaymentValuesExtractor) { }
  
    @Input() category = "";
    @Input() prefix = "";
    @Input() contacts = [];

    @Input() paymentDetails: Payment;
    @Input() minimumTopUp = 40;

    @Input() billingAddress: any;
    @Output() billingAddressChange = new EventEmitter();
    
    @Output() previousStep = new EventEmitter();
    @Output() stepComplete = new EventEmitter();

    get isAutoTopUp() { return this.topUpForm.get('isAutoTopUp').value; }

    topUpForm: FormGroup;
    billingAddressForm: FormGroup;
    cardDetailsForm: FormGroup;

    ngOnInit(): void {
        this.minimumTopUp = 40;

        this.billingAddressForm = this.addressFb
          .withAddressLine1(this.billingAddress.addressLine1)
          .withAddressLine2(this.billingAddress.addressLine2)
          .withAddressLine3(this.billingAddress.addressLine3)
          .withCity(this.billingAddress.city)
          .withCounty(this.billingAddress.county)
          .withCountry(this.billingAddress.country)
          .withPostCode(this.billingAddress.postCode)
          .create();

        this.cardDetailsForm = this.createCardDetailsForm();

        this.topUpForm = this.createTopUpForm();
    }

    createCardDetailsForm(): FormGroup {
      let cardDetailsFormBuilder =  this
        .cardDetailsFb
        .withCardholderName(this.paymentDetails.cardHolderName)
        .withCardNumber(this.paymentDetails.cardNumber)
        .withCvc(this.paymentDetails.cvc)
        .withExpiryMonth(this.paymentDetails.expiryMonth)
        .withExpiryYear(this.paymentDetails.expiryYear)
        .withCardDescription(this.paymentDetails.cardDescription);

      if (this.paymentDetails.saveCardDetails)
        cardDetailsFormBuilder.enableSaveCardDetails();
      else
        cardDetailsFormBuilder.disableSaveCardDetails();

      return cardDetailsFormBuilder.create();
    }

    createTopUpForm(): FormGroup {
      let topUpFormBuilder =  this.topUpFb.withAmount(this.paymentDetails.amount);
      
       if (this.paymentDetails.createAutoTopUp)
         topUpFormBuilder.enableAutoTopUp();
      else 
        topUpFormBuilder.disableAutoTopUp();

      return topUpFormBuilder.create();
    }

    cardDetailsFormChanged(form: FormGroup) {
      this.cardDetailsForm =  form;
    }

    autoTopUpToggled() {
      this.cardDetailsForm.patchValue({ saveCardDetails: this.isAutoTopUp });
    }

    onPreviousStepClick() {
      this.previousStep.emit(this.getData());
    }

    onStepComplete() {

      if (this.topUpForm?.invalid || this.billingAddressForm.invalid || this.cardDetailsForm?.invalid)
      {
        this.topUpForm.markAllAsTouched();
        this.billingAddressForm.markAllAsTouched();
        this.cardDetailsForm.markAllAsTouched();
        return;
      }

      this.stepComplete.emit(this.getData());
    }

    private getData() {
      return { 
        paymentDetails: this.paymentValuesExtractor.newCard(this.cardDetailsForm).paymentValues(this.topUpForm).getPayment(),
        billingAddress: this.addressValuesExtractor.getAddress(this.billingAddressForm)
    }
  }
}