import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMAIL_PATTERN } from '../../../@core/utils/constants';
import { AuthService } from '../../../@core/backend/receptionist/services/auth.service';

@Component({
  selector: 'r247-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class R247LoginComponent implements OnInit {

  LOGIN_ERROR = 'Unable to log you in please check your details and try again.';

  rememberMe = false;
  
  error: string = '';
  loginForm: FormGroup;

  redirectUrl = '/client';

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }

  constructor(
    protected cd: ChangeDetectorRef,
    private fb: FormBuilder,
    protected router: Router,
    private route: ActivatedRoute,
    private authService: AuthService) { }

  ngOnInit(): void {

    this.error = (this.route.snapshot.queryParamMap.get('retry') === '1') ? this.LOGIN_ERROR : '';

    this.loginForm = this.fb.group({
      email: this.fb.control('', [ Validators.pattern(EMAIL_PATTERN), Validators.required]),
      password: this.fb.control('', [Validators.required]),
      rememberMe: this.fb.control(false),
    });
  }

  login(): void {
    this.error = "";
    
    if (this.email.errors?.required || this.password.errors?.required) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.authService.login(this.email.value, this.password.value)
      .then((result: any) => {
        var redirectUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        this.router.navigateByUrl(
          (redirectUrl != null && redirectUrl.length > 0 && redirectUrl.startsWith(`/${result.userType}`)) 
            ? redirectUrl 
            : `/${result.userType}`);
        }, 
        () => {  
          this.error = this.LOGIN_ERROR;
          this.cd.detectChanges();
        });
  }
}
