import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../../common/api/http.service";
import { ExportApi } from "./export.api";

@Injectable()
export class ResellerApi {
  private readonly apiController: string = "reseller";

  constructor(private api: HttpService, private exportApi: ExportApi) {}

  countClients(): Observable<number> {
    return this.api.get(`${this.apiController}/clients/count`);
  }

  countNewClientsPerMonth(): Observable<any> {
    return this.api.get(`${this.apiController}/clients/new/count/monthly`);
  }

  countTotalClientsPerMonth(): Observable<any> {
    return this.api.get(`${this.apiController}/clients/count/monthly`);
  }

  calculateRevenuePerClient(
    periodStart: Date,
    periodEnd: Date
  ): Observable<any> {
    return this.api.get(
      `${
        this.apiController
      }/revenue/client?periodStart=${periodStart.toDateString()}&periodEnd=${periodEnd.toDateString()}`
    );
  }

  exportRevenuePerClient(periodStart: Date, periodEnd: Date): Observable<any> {
    return this.exportApi.get(
      `${
        this.apiController
      }/revenue/client/export?periodStart=${periodStart.toDateString()}&periodEnd=${periodEnd.toDateString()}`
    );
  }

  calculateRevenuePerMonth(): Observable<any> {
    return this.api.get(`${this.apiController}/revenue/monthly`);
  }

  calculateUnRealisedRevenue(): Observable<any> {
    return this.api.get(`${this.apiController}/revenue/unrealised`);
  }
}
