import { Injectable } from "@angular/core";
import moment from "moment";
import { tap } from "rxjs/operators";
import { AuthApi } from "../api/auth.api";
import { FranchiseService } from "./franchise-service";
import { environment } from "../../../../../environments/environment";

@Injectable()
export class AuthService {
  constructor(private api: AuthApi) {}

  private TOKEN_KEY = "token";

  private EXPIRES_KEY = "expires";

  isAuthenticated() {
    var token = this.getToken();

    if (!token) return false;

    const expiration = localStorage.getItem(this.EXPIRES_KEY);
    const expiresAt = JSON.parse(expiration);
    const expiresDate = moment(expiresAt);

    return moment().isBefore(expiresDate);
  }

  getToken() {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  login(userName: string, password: string): Promise<any> {
    return this.api
      .login({
        userName: userName,
        password: password,
        franchiseCode: environment.franchiseCode,
      })
      .pipe(
        tap((result) => {
          localStorage.setItem(this.TOKEN_KEY, result.token.access_token);
          localStorage.setItem(
            this.EXPIRES_KEY,
            JSON.stringify(moment().add(result.token.expires_in))
          );
        })
      )
      .toPromise();
  }

  logout() {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.EXPIRES_KEY);
  }
}
