import { Injectable } from '@angular/core';
import { HttpService } from '../../common/api/http.service';

@Injectable()
export class ReceptionApi {
  private readonly apiController: string = 'reception';

  constructor(private api: HttpService) { }

  getConfig(): any {
    return this.api.get(`${this.apiController}/config`);
  }
}
