import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../common/api/http.service';

@Injectable()
export class AuthApi {

  private readonly apiController: string = 'auth';

  constructor(private api: HttpService) { } 

  login(credentials): Observable<any> {
    return this.api.post(`${this.apiController}/login`, credentials);
  }
}
