<form [formGroup]="topUpInputForm">
    <div class="row">
        <div class="col-xs-6 col-md-3 col-lg-3">
            <label for="amount">Amount</label>
        </div>
        <div class="col-xs-6 col-md-8 col-lg-8">
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <select (change)="broadcastNewAmount()" [value]="minimumTopUp" class="form-control" formControlName="amount">
                        <option value="40">£40</option>
                        <option value="75">£75</option>
                        <option value="100">£100</option>
                        <option value="custom">Custom</option>
                    </select>
                </div>
                <div class="col-xs-6 offset-xs-6 col-md-6 mt-1 mt-md-0" *ngIf="isCustomAmount">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">£</span>
                        </div>
                        <input type="text" (change)="broadcastNewAmount()"
                            placeholder="Enter Your Prefered Amount (minimum £{{minimumTopUp}})" 
                            required
                            class="form-control" 
                            formControlName="customAmount">
                    </div>
                    <r247-validation-message errorMessage="Please top up by at least £{{minimumTopUp}}" [showError]="customAmount?.errors?.min && customAmount?.touched"></r247-validation-message>
                    <r247-validation-message errorMessage="Please enter your top up amount" [showError]="(customAmount?.errors?.required && customAmount?.touched) || (topUpForm?.get('amount')?.errors?.required && topUpForm?.touched)"></r247-validation-message>
                </div>
            </div>
        </div>
    </div>
</form>