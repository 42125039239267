import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { numbersTemplate } from "./number-list-card/numbers-list-template";

@Component({
  selector: "r247-desktop-number-chooser",
  templateUrl: "./desktop-number-chooser.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247DesktopNumberChooserComponent {
  constructor() {}

  @Input() chooseNumberCaption = "Choose Your Number";
  @Input() numberChosenCaption = "Your Chosen Number";
  @Input() reserveNumberCaption = "Reserve My Phone Number";

  @Input() localNumbers = [];
  @Input() nationalNumbers = [];
  @Input() freephoneNumbers = [];

  @Input() selectedLocalCategory = numbersTemplate.localCategories[0];
  @Input() selectedNationalCategory = numbersTemplate.nationalCategories[0];
  @Input() selectedFreephoneCategory = numbersTemplate.freephoneCategories[0];

  @Output() numberConfirmed = new EventEmitter();
  @Output() localNumbersSearched = new EventEmitter<any>();
  @Output() nationalNumbersSearched = new EventEmitter<any>();
  @Output() freephoneNumbersSearched = new EventEmitter<any>();

  get isNumberChosen() {
    return this.selectedVirtualNumber != "";
  }

  category = "";
  selectedVirtualNumber = "";
  prefix = "";
  range = "";
  number = "";
  numbersTemplate = numbersTemplate;

  selectedLocalPrefix = numbersTemplate.defaultLocalPrefix;
  selectedNationalPrefix = numbersTemplate.defaultNationalPrefix;
  selectedFreephonePrefix = numbersTemplate.defaultFreephonePrefix;

  LOCAL_NUMBERS = 0;
  NATIONAL_NUMBERS = 1;
  FREEPHONE_NUMBERS = 2;

  numberType = this.LOCAL_NUMBERS;

  @Input() searchingLocalNumbers = true;
  @Input() searchingNationalNumbers = true;
  @Input() searchingFreephoneNumbers = true;

  setNumberValues(numberArgs) {
    this.setSelectedValues(numberArgs);

    this.category = numberArgs.category;
    this.selectedVirtualNumber = `${numberArgs.prefix} ${numberArgs.range} ${numberArgs.number}`;

    this.prefix = numberArgs.prefix;
    this.range = numberArgs.range;
    this.number = numberArgs.number;
  }

  setSelectedValues(numberArgs) {
    switch (this.numberType) {
      case this.LOCAL_NUMBERS:
        this.selectedLocalPrefix = numberArgs.prefix;
        this.selectedLocalCategory = numberArgs.category;
        break;
      case this.NATIONAL_NUMBERS:
        this.selectedNationalPrefix = numberArgs.prefix;
        this.selectedNationalCategory = numberArgs.category;
        break;
      case this.FREEPHONE_NUMBERS:
        this.selectedFreephonePrefix = numberArgs.prefix;
        this.selectedFreephoneCategory = numberArgs.category;
        break;
    }
  }

  onNumberConfirmClick() {
    this.numberConfirmed.emit({
      category: this.category,
      prefix: this.prefix,
      range: this.range,
      number: this.number,
    });
  }
}
