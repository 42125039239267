<div class="row d-inline d-md-none">
  <div class="col-12 mb-2 pl-0 pr-0">
    <button class="btn btn-secondary" (click)="onExport()" [disabled]="isExporting">{{exportCaption}}</button>
  </div>
    <div class="col-12 mb-2 pl-0 pr-0">
      <select class="form-control" [(ngModel)]="selectedFilter" (ngModelChange)="onFilterSelectChange()">
        <option [value]="THIS_MONTH">This Month</option>
        <option [value]="LAST_MONTH">Last Month</option>
        <option [value]="CUSTOM">Select Date Range</option>
      </select>
    </div>
    <div class="col-12 mb-sm-2 mb-md-2  pl-0 pr-0" *ngIf="selectedFilter === CUSTOM">
      <r247-date-range-picker [fromDate]="startDate" [toDate]="endDate" (dateRangeChange)="dateRangeChange($event)"></r247-date-range-picker>
    </div>
    <div class="col-12  pl-0 pr-0" *ngIf="selectedFilter === CUSTOM">
      <button class="ml-1 btn btn-primary btn-block" (click)="customSearch()">Search</button>
    </div>
</div>
<div class="d-none d-md-block">
  <div class="row mb-2">
    <div class="col-md-10 mb-md-2">
        <div class="d-flex">
            <div class="btn-group mr-1" role="group">
                <button type="button" class="btn btn-secondary" (click)="changeFilter(THIS_MONTH)" [disabled]="selectedFilter === THIS_MONTH">This Month</button>
                <button type="button" class="btn btn-secondary" (click)="changeFilter(LAST_MONTH)" [disabled]="selectedFilter === LAST_MONTH">Last Month</button>
                <button type="button" class="btn btn-secondary" (click)="changeFilter(CUSTOM)" [disabled]="selectedFilter === CUSTOM">Select Date Range</button>
            </div>
            <r247-date-range-picker *ngIf="selectedFilter === CUSTOM" [fromDate]="startDate" [toDate]="endDate" (dateRangeChange)="dateRangeChange($event)"></r247-date-range-picker>
            <button class="ml-1 btn btn-primary" *ngIf="selectedFilter === CUSTOM" (click)="onSearch()">Search</button>
        </div>
    </div>
    <div class="col-md-2">
        <button class="btn btn-secondary float-right ml-2" (click)="onExport()" [disabled]="isExporting">{{exportCaption}}</button>
    </div>
  </div>
</div>