<div *ngIf="useGenericMarketingScreen; else twoFourSevenMarketingScreen">
    <r247-nav-bar accountLink="/register/number" accountButtonText="Register">
    </r247-nav-bar>
    <div class="container-fluid">
        <div class="row" style="margin-top: -0.5rem;">
            <div class="col-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
<ng-template #twoFourSevenMarketingScreen>
    <marketing-247></marketing-247>
</ng-template>