import { Component, ElementRef, ViewChild } from "@angular/core";
import {
  JUNO_RESELLER_CODE,
  TWO_FOUR_SEVEN_RECEPTIONIST_RESELLER_CODE,
} from "../../environments/enviroment-consts";
import { FranchiseService } from "../@core/backend/receptionist/services/franchise-service";
import { environment } from "../../environments/environment";

@Component({
  selector: "r247-marketing",
  templateUrl: "./marketing.component.html",
})
export class R247MarketingComponent {
  constructor(private franchiseService: FranchiseService) {}

  useGenericMarketingScreen = false;

  @ViewChild("twoFourSevenMarketingScreen")
  twoFourSevenMarketingScreen: ElementRef;

  ngAfterViewInit() {
    this.useGenericMarketingScreen =
      environment.franchiseCode != TWO_FOUR_SEVEN_RECEPTIONIST_RESELLER_CODE;
  }
}
