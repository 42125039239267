import { ChangeDetectionStrategy, Component, OnInit, } from '@angular/core';
import { PricingService } from '../../../@core/backend/receptionist/services/pricing-service';

@Component({
  selector: 'r247-marketing-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class R247MarketingPricingComponent implements OnInit {
  constructor(private pricing: PricingService) {}

    prices = {};

    receptionistCharge = '';
    numberCharges = [];
    monthlyServiceCharges = [];

    ngOnInit(): void {
        this.pricing.getReceptionistCharge().subscribe((result) => {
            this.receptionistCharge = result;
        });

        this.pricing.getNumberCharges().subscribe((result) => {
            this.numberCharges = result;
        });

        this.pricing.getMonthlyServiceCharges().subscribe((result) => {
            this.monthlyServiceCharges = result;
        });
    }
}