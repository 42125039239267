import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../common/api/http.service';
import { ExportApi } from './export.api';

@Injectable()
export class ReceptionistApi {
  private readonly apiController: string = 'receptionist';

  constructor(private api: HttpService, private exportApi: ExportApi) { }

  getMonthlyTalkTime() {
    return this.api.get(`${this.apiController}/talk-time/monthly`);
  }

  getDailyTalkTime() {
    return this.api.get(`${this.apiController}/talk-time/daily`);
  }

  getCalls(startDate: Date, endDate: Date):any{
      return this.api.get(`${this.apiController}/calls?${this.dateQueryString(startDate, endDate)}`)
  }
  
  getMonthlyCalls() {
    return this.api.get(`${this.apiController}/calls/monthly`);
  }

  getDailyCalls() {
    return this.api.get(`${this.apiController}/calls/daily`);
  }

  exportCalls(startDate: Date, endDate: Date):Observable<any>{
    return this.exportApi.get(`${this.apiController}/calls/export?${this.dateQueryString(startDate, endDate)}`); 
  }

  getTodaysMessages():any {
    return this.api.get(`${this.apiController}/messages/today`);
  }

  getThisMonthsMessages():any {
    return this.api.get(`${this.apiController}/messages/month`);
  }

  getMessages(startDate: Date, endDate: Date):any {
    return this.api.get(`${this.apiController}/messages?${this.dateQueryString(startDate, endDate)}`);
  }

  exportMessages(startDate: Date, endDate: Date):Observable<any>{
    return this.exportApi.get(`${this.apiController}/messages/export?${this.dateQueryString(startDate, endDate)}`); 
  }

  private dateQueryString(startDate: Date, endDate: Date) {
    return `startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}`;
  }
}
