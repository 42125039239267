
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { R247MarketingScreenComponent } from './screens/marketing/marketing-screen.component';
import { R247MarketingComponent } from './marketing.component';

const routes: Routes = [
    {
        path: '',
        component: R247MarketingComponent,
        children: [
            {
                path: '',
                component: R247MarketingScreenComponent,
            }
        ],
    }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class MarketingRoutingModule {
}
