

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { signUpForm, signUpResult } from '../../../interfaces/common/signUp';
import { SignUpApi } from '../api/sign-up-api';

@Injectable()
export class SignUpService {

    constructor(private api: SignUpApi) { }
    
    signUp(signUpForm:signUpForm): Observable<signUpResult> {
        return this.api.signUp(signUpForm);
    }

    purchaseNumber(number:any): Observable<any> {
        return this.api.purchaseNumber(number);
    }
}