import { ChangeDetectionStrategy, Component, } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'r247-marketing-register',
  templateUrl: './marketing-register.component.html',
  styleUrls: ['./marketing-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class R247MarketingRegisterComponent {
  constructor(protected router: Router) {}
    
    goToRegister(selectedNumber) {      
      return this.router.navigateByUrl(`/register/account?category=${selectedNumber.category}&prefix=${selectedNumber.prefix}&range=${selectedNumber.range}&number=${selectedNumber.number}`);
    }
}
