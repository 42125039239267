import { ChangeDetectionStrategy, Component, } from '@angular/core';

@Component({
  selector: 'r247-marketing-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class R247MarketingHighlightsComponent {
  constructor() {}
}