import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Client } from "../../../interfaces/common/signUp";
import { HttpService } from "../../common/api/http.service";
@Injectable()
export class ClientService {
  constructor(private api: HttpService) {}

  private readonly apiController: string = "client";

  getBalance(): Observable<any> {
    return this.api.get(`${this.apiController}/balance`);
  }

  getCompany(): Observable<Client> {
    return this.api.get(`${this.apiController}/`);
  }

  isSuspended(): Observable<boolean> {
    return this.api.get(`${this.apiController}/suspended`);
  }

  delete(): Observable<boolean> {
    return this.api.get(`${this.apiController}/delete`);
  }
}
