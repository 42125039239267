import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "./@auth/auth.guard";
import { ModeCheckerComponent } from "./mode-checker/mode-checker.component";

const routes: Routes = [
  {
    path: "client",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./client/client.module").then((m) => m.ClientModule),
  },
  {
    path: "reseller",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./reseller/reseller.module").then((m) => m.ResellerModule),
  },
  {
    path: "franchise",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./franchise/franchise.module").then((m) => m.FranchiseModule),
  },
  {
    path: "auth",
    loadChildren: () => import("./@auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./registration/registration.module").then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: "mode",
    component: ModeCheckerComponent,
  },
  {
    path: "",
    loadChildren: () =>
      import("./marketing/marketing.module").then((m) => m.MarketingModule),
  },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
