const localPrefixes = [
  { code: "0113", area: "Leeds", description: "0113 - Leeds" },
  { code: "0114", area: "Sheffield", description: "0114 - Sheffield" },
  { code: "0115", area: "Nottingham", description: "0115 - Nottingham" },
  { code: "0116", area: "Leicester", description: "0116 - Leicester" },
  { code: "0117", area: "Bristol", description: "0117 - Bristol" },
  { code: "0118", area: "Reading", description: "0118 - Reading" },
  { code: "01200", area: "Clitheroe", description: "01200 - Clitheroe" },
  { code: "01202", area: "Bournemouth", description: "01202 - Bournemouth" },
  { code: "01204", area: "Bolton", description: "01204 - Bolton" },
  { code: "01205", area: "Boston", description: "01205 - Boston" },
  { code: "01206", area: "Colchester", description: "01206 - Colchester" },
  { code: "01207", area: "Consett", description: "01207 - Consett" },
  { code: "01208", area: "Bodmin", description: "01208 - Bodmin" },
  { code: "01209", area: "Redruth", description: "01209 - Redruth" },
  { code: "0121", area: "Birmingham", description: "0121 - Birmingham" },
  { code: "01223", area: "Cambridge", description: "01223 - Cambridge" },
  { code: "01224", area: "Aberdeen", description: "01224 - Aberdeen" },
  { code: "01225", area: "Bath", description: "01225 - Bath" },
  { code: "01226", area: "Barnsley", description: "01226 - Barnsley" },
  { code: "01227", area: "Canterbury", description: "01227 - Canterbury" },
  { code: "01228", area: "Carlisle", description: "01228 - Carlisle" },
  {
    code: "012292",
    area: "Barrow-in-Furness",
    description: "012292 - Barrow-in-Furness",
  },
  { code: "012293", area: "Millom", description: "012293 - Millom" },
  {
    code: "012294",
    area: "Barrow-in-Furness",
    description: "012294 - Barrow-in-Furness",
  },
  {
    code: "012295",
    area: "Barrow-in-Furness",
    description: "012295 - Barrow-in-Furness",
  },
  {
    code: "012296",
    area: "Barrow-in-Furness",
    description: "012296 - Barrow-in-Furness",
  },
  { code: "012297", area: "Millom", description: "012297 - Millom" },
  {
    code: "012298",
    area: "Barrow-in-Furness",
    description: "012298 - Barrow-in-Furness",
  },
  { code: "012299", area: "Millom", description: "012299 - Millom" },
  {
    code: "01233",
    area: "Ashford (Kent)",
    description: "01233 - Ashford (Kent)",
  },
  { code: "01234", area: "Bedford", description: "01234 - Bedford" },
  { code: "01235", area: "Abingdon", description: "01235 - Abingdon" },
  { code: "01236", area: "Coatbridge", description: "01236 - Coatbridge" },
  { code: "01237", area: "Bideford", description: "01237 - Bideford" },
  { code: "01239", area: "Cardigan", description: "01239 - Cardigan" },
  { code: "01241", area: "Arbroath", description: "01241 - Arbroath" },
  { code: "01242", area: "Cheltenham", description: "01242 - Cheltenham" },
  { code: "01243", area: "Chichester", description: "01243 - Chichester" },
  { code: "01244", area: "Chester", description: "01244 - Chester" },
  { code: "01245", area: "Chelmsford", description: "01245 - Chelmsford" },
  { code: "01246", area: "Chesterfield", description: "01246 - Chesterfield" },
  {
    code: "01248",
    area: "Bangor (Gwynedd)",
    description: "01248 - Bangor (Gwynedd)",
  },
  { code: "01249", area: "Chippenham", description: "01249 - Chippenham" },
  { code: "01250", area: "Blairgowrie", description: "01250 - Blairgowrie" },
  { code: "01252", area: "Aldershot", description: "01252 - Aldershot" },
  { code: "01253", area: "Blackpool", description: "01253 - Blackpool" },
  { code: "01254", area: "Blackburn", description: "01254 - Blackburn" },
  {
    code: "01255",
    area: "Clacton-on-Sea",
    description: "01255 - Clacton-on-Sea",
  },
  { code: "01256", area: "Basingstoke", description: "01256 - Basingstoke" },
  { code: "01257", area: "Coppull", description: "01257 - Coppull" },
  { code: "01258", area: "Blandford", description: "01258 - Blandford" },
  { code: "01259", area: "Alloa", description: "01259 - Alloa" },
  { code: "01260", area: "Congleton", description: "01260 - Congleton" },
  { code: "01261", area: "Banff", description: "01261 - Banff" },
  { code: "01262", area: "Bridlington", description: "01262 - Bridlington" },
  { code: "01263", area: "Cromer", description: "01263 - Cromer" },
  { code: "01264", area: "Andover", description: "01264 - Andover" },
  { code: "01267", area: "Carmarthen", description: "01267 - Carmarthen" },
  { code: "01268", area: "Basildon", description: "01268 - Basildon" },
  { code: "01269", area: "Ammanford", description: "01269 - Ammanford" },
  { code: "01270", area: "Crewe", description: "01270 - Crewe" },
  { code: "01271", area: "Barnstaple", description: "01271 - Barnstaple" },
  { code: "01273", area: "Brighton", description: "01273 - Brighton" },
  { code: "01274", area: "Bradford", description: "01274 - Bradford" },
  { code: "01275", area: "Clevedon", description: "01275 - Clevedon" },
  { code: "01276", area: "Camberley", description: "01276 - Camberley" },
  { code: "01277", area: "Brentwood", description: "01277 - Brentwood" },
  { code: "01278", area: "Bridgwater", description: "01278 - Bridgwater" },
  {
    code: "01279",
    area: "Bishops Stortford",
    description: "01279 - Bishops Stortford",
  },
  { code: "01280", area: "Buckingham", description: "01280 - Buckingham" },
  { code: "01282", area: "Burnley", description: "01282 - Burnley" },
  {
    code: "01283",
    area: "Burton-on-Trent",
    description: "01283 - Burton-on-Trent",
  },
  {
    code: "01284",
    area: "Bury St Edmunds",
    description: "01284 - Bury St Edmunds",
  },
  { code: "01285", area: "Cirencester", description: "01285 - Cirencester" },
  { code: "01286", area: "Caernarfon", description: "01286 - Caernarfon" },
  { code: "01287", area: "Guisborough", description: "01287 - Guisborough" },
  { code: "01288", area: "Bude", description: "01288 - Bude" },
  {
    code: "01289",
    area: "Berwick-upon-Tweed",
    description: "01289 - Berwick-upon-Tweed",
  },
  { code: "01290", area: "Cumnock", description: "01290 - Cumnock" },
  { code: "01291", area: "Chepstow", description: "01291 - Chepstow" },
  { code: "01292", area: "Ayr", description: "01292 - Ayr" },
  { code: "01293", area: "Crawley", description: "01293 - Crawley" },
  { code: "01294", area: "Ardrossan", description: "01294 - Ardrossan" },
  { code: "01295", area: "Banbury", description: "01295 - Banbury" },
  { code: "01296", area: "Aylesbury", description: "01296 - Aylesbury" },
  { code: "01297", area: "Axminster", description: "01297 - Axminster" },
  { code: "01298", area: "Buxton", description: "01298 - Buxton" },
  { code: "01299", area: "Bewdley", description: "01299 - Bewdley" },
  { code: "01300", area: "Cerne Abbas", description: "01300 - Cerne Abbas" },
  { code: "01301", area: "Arrochar", description: "01301 - Arrochar" },
  { code: "01302", area: "Doncaster", description: "01302 - Doncaster" },
  { code: "01303", area: "Folkestone", description: "01303 - Folkestone" },
  { code: "01304", area: "Dover", description: "01304 - Dover" },
  { code: "01305", area: "Dorchester", description: "01305 - Dorchester" },
  {
    code: "01305",
    area: "Martinstown (Dorset)",
    description: "01305 - Martinstown (Dorset)",
  },
  { code: "01306", area: "Dorking", description: "01306 - Dorking" },
  { code: "01307", area: "Forfar", description: "01307 - Forfar" },
  { code: "01308", area: "Bridport", description: "01308 - Bridport" },
  { code: "01309", area: "Forres", description: "01309 - Forres" },
  { code: "0131", area: "Edinburgh", description: "0131 - Edinburgh" },
  {
    code: "01320",
    area: "Fort Augustus",
    description: "01320 - Fort Augustus",
  },
  { code: "01322", area: "Dartford", description: "01322 - Dartford" },
  { code: "01323", area: "Eastbourne", description: "01323 - Eastbourne" },
  { code: "01324", area: "Falkirk", description: "01324 - Falkirk" },
  { code: "01325", area: "Darlington", description: "01325 - Darlington" },
  { code: "01326", area: "Falmouth", description: "01326 - Falmouth" },
  { code: "01327", area: "Daventry", description: "01327 - Daventry" },
  { code: "01328", area: "Fakenham", description: "01328 - Fakenham" },
  { code: "01329", area: "Fareham", description: "01329 - Fareham" },
  { code: "01330", area: "Banchory", description: "01330 - Banchory" },
  { code: "01332", area: "Derby", description: "01332 - Derby" },
  { code: "01333", area: "Peat Inn", description: "01333 - Peat Inn" },
  { code: "01334", area: "St Andrews", description: "01334 - St Andrews" },
  { code: "01335", area: "Ashbourne", description: "01335 - Ashbourne" },
  { code: "01337", area: "Ladybank", description: "01337 - Ladybank" },
  { code: "013392", area: "Aboyne", description: "013392 - Aboyne" },
  { code: "013393", area: "Aboyne", description: "013393 - Aboyne" },
  { code: "013394", area: "Ballater", description: "013394 - Ballater" },
  { code: "013395", area: "Aboyne", description: "013395 - Aboyne" },
  { code: "013396", area: "Ballater", description: "013396 - Ballater" },
  { code: "013397", area: "Ballater", description: "013397 - Ballater" },
  { code: "013398", area: "Aboyne", description: "013398 - Aboyne" },
  { code: "013399", area: "Ballater", description: "013399 - Ballater" },
  {
    code: "01340",
    area: "Craigellachie",
    description: "01340 - Craigellachie",
  },
  { code: "01341", area: "Barmouth", description: "01341 - Barmouth" },
  {
    code: "01342",
    area: "East Grinstead",
    description: "01342 - East Grinstead",
  },
  { code: "01343", area: "Elgin", description: "01343 - Elgin" },
  { code: "01344", area: "Bracknell", description: "01344 - Bracknell" },
  { code: "01346", area: "Fraserburgh", description: "01346 - Fraserburgh" },
  { code: "01347", area: "Easingwold", description: "01347 - Easingwold" },
  { code: "01348", area: "Fishguard", description: "01348 - Fishguard" },
  { code: "01349", area: "Dingwall", description: "01349 - Dingwall" },
  { code: "01350", area: "Dunkeld", description: "01350 - Dunkeld" },
  { code: "01352", area: "Mold", description: "01352 - Mold" },
  { code: "01353", area: "Ely", description: "01353 - Ely" },
  { code: "01354", area: "Chatteris", description: "01354 - Chatteris" },
  {
    code: "01355",
    area: "East Kilbride",
    description: "01355 - East Kilbride",
  },
  { code: "01356", area: "Brechin", description: "01356 - Brechin" },
  { code: "01357", area: "Strathaven", description: "01357 - Strathaven" },
  { code: "01358", area: "Ellon", description: "01358 - Ellon" },
  { code: "01359", area: "Pakenham", description: "01359 - Pakenham" },
  { code: "01360", area: "Killearn", description: "01360 - Killearn" },
  { code: "01361", area: "Duns", description: "01361 - Duns" },
  { code: "01362", area: "Dereham", description: "01362 - Dereham" },
  { code: "01363", area: "Crediton", description: "01363 - Crediton" },
  { code: "01364", area: "Ashburton", description: "01364 - Ashburton" },
  {
    code: "01366",
    area: "Downham Market",
    description: "01366 - Downham Market",
  },
  { code: "01367", area: "Faringdon", description: "01367 - Faringdon" },
  { code: "01368", area: "Dunbar", description: "01368 - Dunbar" },
  { code: "01369", area: "Dunoon", description: "01369 - Dunoon" },
  { code: "01371", area: "Great Dunmow", description: "01371 - Great Dunmow" },
  { code: "01372", area: "Esher", description: "01372 - Esher" },
  { code: "01373", area: "Frome", description: "01373 - Frome" },
  {
    code: "01375",
    area: "Grays Thurrock",
    description: "01375 - Grays Thurrock",
  },
  { code: "01376", area: "Braintree", description: "01376 - Braintree" },
  { code: "01377", area: "Driffield", description: "01377 - Driffield" },
  { code: "01379", area: "Diss", description: "01379 - Diss" },
  { code: "01380", area: "Devizes", description: "01380 - Devizes" },
  { code: "01381", area: "Fortrose", description: "01381 - Fortrose" },
  { code: "01382", area: "Dundee", description: "01382 - Dundee" },
  { code: "01383", area: "Dunfermline", description: "01383 - Dunfermline" },
  { code: "01384", area: "Dudley", description: "01384 - Dudley" },
  { code: "01386", area: "Evesham", description: "01386 - Evesham" },
  { code: "01387", area: "Dumfries", description: "01387 - Dumfries" },
  { code: "013873", area: "Langholm", description: "013873 - Langholm" },
  { code: "013882", area: "Stanhope", description: "013882 - Stanhope" },
  {
    code: "013883",
    area: "Bishop Auckland",
    description: "013883 - Bishop Auckland",
  },
  {
    code: "013884",
    area: "Bishop Auckland",
    description: "013884 - Bishop Auckland",
  },
  { code: "013885", area: "Stanhope", description: "013885 - Stanhope" },
  {
    code: "013886",
    area: "Bishop Auckland",
    description: "013886 - Bishop Auckland",
  },
  {
    code: "013887",
    area: "Bishop Auckland",
    description: "013887 - Bishop Auckland",
  },
  {
    code: "013888",
    area: "Bishop Auckland",
    description: "013888 - Bishop Auckland",
  },
  {
    code: "013889",
    area: "Bishop Auckland",
    description: "013889 - Bishop Auckland",
  },
  { code: "01389", area: "Dumbarton", description: "01389 - Dumbarton" },
  { code: "01392", area: "Exeter", description: "01392 - Exeter" },
  { code: "01394", area: "Felixstowe", description: "01394 - Felixstowe" },
  {
    code: "01395",
    area: "Budleigh Salterton",
    description: "01395 - Budleigh Salterton",
  },
  { code: "01397", area: "Fort William", description: "01397 - Fort William" },
  { code: "01398", area: "Dulverton", description: "01398 - Dulverton" },
  { code: "01400", area: "Honington", description: "01400 - Honington" },
  { code: "01403", area: "Horsham", description: "01403 - Horsham" },
  { code: "01404", area: "Honiton", description: "01404 - Honiton" },
  { code: "01405", area: "Goole", description: "01405 - Goole" },
  { code: "01406", area: "Holbeach", description: "01406 - Holbeach" },
  { code: "01407", area: "Holyhead", description: "01407 - Holyhead" },
  { code: "01408", area: "Golspie", description: "01408 - Golspie" },
  { code: "01409", area: "Holsworthy", description: "01409 - Holsworthy" },
  { code: "0141", area: "Glasgow", description: "0141 - Glasgow" },
  { code: "01420", area: "Alton", description: "01420 - Alton" },
  { code: "01422", area: "Halifax", description: "01422 - Halifax" },
  { code: "014232", area: "Harrogate", description: "014232 - Harrogate" },
  {
    code: "014233",
    area: "Boroughbridge",
    description: "014233 - Boroughbridge",
  },
  {
    code: "014234",
    area: "Boroughbridge",
    description: "014234 - Boroughbridge",
  },
  { code: "014235", area: "Harrogate", description: "014235 - Harrogate" },
  { code: "014236", area: "Harrogate", description: "014236 - Harrogate" },
  { code: "014237", area: "Harrogate", description: "014237 - Harrogate" },
  { code: "014238", area: "Harrogate", description: "014238 - Harrogate" },
  {
    code: "014239",
    area: "Boroughbridge",
    description: "014239 - Boroughbridge",
  },
  { code: "01424", area: "Hastings", description: "01424 - Hastings" },
  { code: "01425", area: "Ringwood", description: "01425 - Ringwood" },
  { code: "01427", area: "Gainsborough", description: "01427 - Gainsborough" },
  { code: "01428", area: "Haslemere", description: "01428 - Haslemere" },
  { code: "01429", area: "Hartlepool", description: "01429 - Hartlepool" },
  { code: "014302", area: "North Cave", description: "014302 - North Cave" },
  { code: "014303", area: "North Cave", description: "014303 - North Cave" },
  { code: "014304", area: "North Cave", description: "014304 - North Cave" },
  { code: "014305", area: "North Cave", description: "014305 - North Cave" },
  {
    code: "014306",
    area: "Market Weighton",
    description: "014306 - Market Weighton",
  },
  {
    code: "014307",
    area: "Market Weighton",
    description: "014307 - Market Weighton",
  },
  {
    code: "014308",
    area: "Market Weighton",
    description: "014308 - Market Weighton",
  },
  {
    code: "014309",
    area: "Market Weighton",
    description: "014309 - Market Weighton",
  },
  { code: "01431", area: "Helmsdale", description: "01431 - Helmsdale" },
  { code: "01432", area: "Hereford", description: "01432 - Hereford" },
  { code: "01433", area: "Hathersage", description: "01433 - Hathersage" },
  { code: "014342", area: "Bellingham", description: "014342 - Bellingham" },
  { code: "014343", area: "Haltwhistle", description: "014343 - Haltwhistle" },
  { code: "014344", area: "Bellingham", description: "014344 - Bellingham" },
  { code: "014345", area: "Haltwhistle", description: "014345 - Haltwhistle" },
  { code: "014346", area: "Hexham", description: "014346 - Hexham" },
  { code: "014347", area: "Hexham", description: "014347 - Hexham" },
  { code: "014348", area: "Hexham", description: "014348 - Hexham" },
  { code: "014349", area: "Bellingham", description: "014349 - Bellingham" },
  { code: "01435", area: "Heathfield", description: "01435 - Heathfield" },
  { code: "01436", area: "Helensburgh", description: "01436 - Helensburgh" },
  { code: "014372", area: "Clynderwen", description: "014372 - Clynderwen" },
  { code: "014373", area: "Clynderwen", description: "014373 - Clynderwen" },
  { code: "014374", area: "Clynderwen", description: "014374 - Clynderwen" },
  { code: "014375", area: "Clynderwen", description: "014375 - Clynderwen" },
  {
    code: "014376",
    area: "Haverfordwest",
    description: "014376 - Haverfordwest",
  },
  {
    code: "014377",
    area: "Haverfordwest",
    description: "014377 - Haverfordwest",
  },
  {
    code: "014378",
    area: "Haverfordwest",
    description: "014378 - Haverfordwest",
  },
  {
    code: "014379",
    area: "Haverfordwest",
    description: "014379 - Haverfordwest",
  },
  { code: "01438", area: "Stevenage", description: "01438 - Stevenage" },
  { code: "01439", area: "Helmsley", description: "01439 - Helmsley" },
  { code: "01440", area: "Haverhill", description: "01440 - Haverhill" },
  {
    code: "01442",
    area: "Hemel Hempstead",
    description: "01442 - Hemel Hempstead",
  },
  { code: "01443", area: "Pontypridd", description: "01443 - Pontypridd" },
  {
    code: "01444",
    area: "Haywards Heath",
    description: "01444 - Haywards Heath",
  },
  { code: "01445", area: "Gairloch", description: "01445 - Gairloch" },
  { code: "01446", area: "Barry", description: "01446 - Barry" },
  { code: "01449", area: "Stowmarket", description: "01449 - Stowmarket" },
  { code: "01450", area: "Hawick", description: "01450 - Hawick" },
  {
    code: "01451",
    area: "Stow-on-the-Wold",
    description: "01451 - Stow-on-the-Wold",
  },
  { code: "01452", area: "Gloucester", description: "01452 - Gloucester" },
  { code: "01453", area: "Dursley", description: "01453 - Dursley" },
  {
    code: "01454",
    area: "Chipping Sodbury",
    description: "01454 - Chipping Sodbury",
  },
  { code: "01455", area: "Hinckley", description: "01455 - Hinckley" },
  { code: "01456", area: "Glenurquhart", description: "01456 - Glenurquhart" },
  { code: "01457", area: "Glossop", description: "01457 - Glossop" },
  { code: "01458", area: "Glastonbury", description: "01458 - Glastonbury" },
  { code: "01460", area: "Chard", description: "01460 - Chard" },
  { code: "01461", area: "Gretna", description: "01461 - Gretna" },
  { code: "01462", area: "Hitchin", description: "01462 - Hitchin" },
  { code: "01463", area: "Inverness", description: "01463 - Inverness" },
  { code: "01464", area: "Insch", description: "01464 - Insch" },
  { code: "01465", area: "Girvan", description: "01465 - Girvan" },
  { code: "01466", area: "Huntly", description: "01466 - Huntly" },
  { code: "01467", area: "Inverurie", description: "01467 - Inverurie" },
  { code: "01469", area: "Killingholme", description: "01469 - Killingholme" },
  {
    code: "01470",
    area: "Isle of Skye - Edinbane",
    description: "01470 - Isle of Skye - Edinbane",
  },
  {
    code: "01471",
    area: "Isle of Skye - Broadford",
    description: "01471 - Isle of Skye - Broadford",
  },
  { code: "01472", area: "Grimsby", description: "01472 - Grimsby" },
  { code: "01473", area: "Ipswich", description: "01473 - Ipswich" },
  { code: "01474", area: "Gravesend", description: "01474 - Gravesend" },
  { code: "01475", area: "Greenock", description: "01475 - Greenock" },
  { code: "01476", area: "Grantham", description: "01476 - Grantham" },
  {
    code: "01477",
    area: "Holmes Chapel",
    description: "01477 - Holmes Chapel",
  },
  {
    code: "01478",
    area: "Isle of Skye - Portree",
    description: "01478 - Isle of Skye - Portree",
  },
  {
    code: "01479",
    area: "Grantown-on-Spey",
    description: "01479 - Grantown-on-Spey",
  },
  { code: "01480", area: "Huntingdon", description: "01480 - Huntingdon" },
  { code: "01481", area: "Guernsey", description: "01481 - Guernsey" },
  { code: "01482", area: "Hull", description: "01482 - Hull" },
  { code: "01483", area: "Guildford", description: "01483 - Guildford" },
  { code: "01484", area: "Huddersfield", description: "01484 - Huddersfield" },
  { code: "01485", area: "Hunstanton", description: "01485 - Hunstanton" },
  { code: "01487", area: "Warboys", description: "01487 - Warboys" },
  { code: "01488", area: "Hungerford", description: "01488 - Hungerford" },
  {
    code: "01489",
    area: "Bishops Waltham",
    description: "01489 - Bishops Waltham",
  },
  { code: "01490", area: "Corwen", description: "01490 - Corwen" },
  {
    code: "01491",
    area: "Henley-on-Thames",
    description: "01491 - Henley-on-Thames",
  },
  { code: "01492", area: "Colwyn Bay", description: "01492 - Colwyn Bay" },
  {
    code: "01493",
    area: "Great Yarmouth",
    description: "01493 - Great Yarmouth",
  },
  { code: "01494", area: "High Wycombe", description: "01494 - High Wycombe" },
  { code: "01495", area: "Pontypool", description: "01495 - Pontypool" },
  { code: "01496", area: "Port Ellen", description: "01496 - Port Ellen" },
  { code: "01497", area: "Hay-on-Wye", description: "01497 - Hay-on-Wye" },
  { code: "01499", area: "Inveraray", description: "01499 - Inveraray" },
  { code: "01501", area: "Harthill", description: "01501 - Harthill" },
  { code: "01502", area: "Lowestoft", description: "01502 - Lowestoft" },
  { code: "01503", area: "Looe", description: "01503 - Looe" },
  { code: "01505", area: "Johnstone", description: "01505 - Johnstone" },
  { code: "01506", area: "Bathgate", description: "01506 - Bathgate" },
  { code: "015072", area: "Spilsby", description: "015072 - Spilsby" },
  { code: "015073", area: "Louth", description: "015073 - Louth" },
  {
    code: "015074",
    area: "Alford (Lincs)",
    description: "015074 - Alford (Lincs)",
  },
  { code: "015075", area: "Spilsby", description: "015075 - Spilsby" },
  { code: "015076", area: "Louth", description: "015076 - Louth" },
  { code: "015077", area: "Louth", description: "015077 - Louth" },
  {
    code: "015078",
    area: "Alford (Lincs)",
    description: "015078 - Alford (Lincs)",
  },
  {
    code: "015079",
    area: "Alford (Lincs)",
    description: "015079 - Alford (Lincs)",
  },
  { code: "01508", area: "Brooke", description: "01508 - Brooke" },
  { code: "01509", area: "Loughborough", description: "01509 - Loughborough" },
  { code: "0151", area: "Liverpool", description: "0151 - Liverpool" },
  { code: "01520", area: "Lochcarron", description: "01520 - Lochcarron" },
  { code: "01522", area: "Lincoln", description: "01522 - Lincoln" },
  { code: "01524", area: "Lancaster", description: "01524 - Lancaster" },
  { code: "015242", area: "Hornby", description: "015242 - Hornby" },
  {
    code: "01525",
    area: "Leighton Buzzard",
    description: "01525 - Leighton Buzzard",
  },
  { code: "01526", area: "Martin", description: "01526 - Martin" },
  { code: "01527", area: "Redditch", description: "01527 - Redditch" },
  { code: "01528", area: "Laggan", description: "01528 - Laggan" },
  { code: "01529", area: "Sleaford", description: "01529 - Sleaford" },
  { code: "01530", area: "Coalville", description: "01530 - Coalville" },
  { code: "01531", area: "Ledbury", description: "01531 - Ledbury" },
  { code: "01534", area: "Jersey", description: "01534 - Jersey" },
  { code: "01535", area: "Keighley", description: "01535 - Keighley" },
  { code: "01536", area: "Kettering", description: "01536 - Kettering" },
  { code: "01538", area: "Ipstones", description: "01538 - Ipstones" },
  { code: "01539", area: "Kendal", description: "01539 - Kendal" },
  { code: "015394", area: "Hawkshead", description: "015394 - Hawkshead" },
  {
    code: "015395",
    area: "Grange-over-Sands",
    description: "015395 - Grange-over-Sands",
  },
  { code: "015396", area: "Sedbergh", description: "015396 - Sedbergh" },
  { code: "01540", area: "Kingussie", description: "01540 - Kingussie" },
  { code: "01542", area: "Keith", description: "01542 - Keith" },
  { code: "01543", area: "Cannock", description: "01543 - Cannock" },
  { code: "01544", area: "Kington", description: "01544 - Kington" },
  { code: "01545", area: "Llanarth", description: "01545 - Llanarth" },
  { code: "01546", area: "Lochgilphead", description: "01546 - Lochgilphead" },
  { code: "01547", area: "Knighton", description: "01547 - Knighton" },
  { code: "01548", area: "Kingsbridge", description: "01548 - Kingsbridge" },
  { code: "01549", area: "Lairg", description: "01549 - Lairg" },
  { code: "01550", area: "Llandovery", description: "01550 - Llandovery" },
  { code: "01553", area: "Kings Lynn", description: "01553 - Kings Lynn" },
  { code: "01554", area: "Llanelli", description: "01554 - Llanelli" },
  { code: "01555", area: "Lanark", description: "01555 - Lanark" },
  {
    code: "01556",
    area: "Castle Douglas",
    description: "01556 - Castle Douglas",
  },
  {
    code: "01557",
    area: "Kirkcudbright",
    description: "01557 - Kirkcudbright",
  },
  { code: "01558", area: "Llandeilo", description: "01558 - Llandeilo" },
  { code: "01559", area: "Llandysul", description: "01559 - Llandysul" },
  { code: "01560", area: "Moscow", description: "01560 - Moscow" },
  { code: "01561", area: "Laurencekirk", description: "01561 - Laurencekirk" },
  {
    code: "01562",
    area: "Kidderminster",
    description: "01562 - Kidderminster",
  },
  { code: "01563", area: "Kilmarnock", description: "01563 - Kilmarnock" },
  { code: "01564", area: "Lapworth", description: "01564 - Lapworth" },
  { code: "01565", area: "Knutsford", description: "01565 - Knutsford" },
  { code: "01566", area: "Launceston", description: "01566 - Launceston" },
  { code: "01567", area: "Killin", description: "01567 - Killin" },
  { code: "01568", area: "Leominster", description: "01568 - Leominster" },
  { code: "01569", area: "Stonehaven", description: "01569 - Stonehaven" },
  { code: "01570", area: "Lampeter", description: "01570 - Lampeter" },
  { code: "01571", area: "Lochinver", description: "01571 - Lochinver" },
  { code: "01572", area: "Oakham", description: "01572 - Oakham" },
  { code: "01573", area: "Kelso", description: "01573 - Kelso" },
  { code: "01575", area: "Kirriemuir", description: "01575 - Kirriemuir" },
  { code: "01576", area: "Lockerbie", description: "01576 - Lockerbie" },
  { code: "01577", area: "Kinross", description: "01577 - Kinross" },
  { code: "01578", area: "Lauder", description: "01578 - Lauder" },
  { code: "01579", area: "Liskeard", description: "01579 - Liskeard" },
  { code: "01580", area: "Cranbrook", description: "01580 - Cranbrook" },
  { code: "01581", area: "New Luce", description: "01581 - New Luce" },
  { code: "01582", area: "Luton", description: "01582 - Luton" },
  { code: "01583", area: "Carradale", description: "01583 - Carradale" },
  { code: "01584", area: "Ludlow", description: "01584 - Ludlow" },
  { code: "01586", area: "Campbeltown", description: "01586 - Campbeltown" },
  {
    code: "01588",
    area: "Bishops Castle",
    description: "01588 - Bishops Castle",
  },
  { code: "01590", area: "Lymington", description: "01590 - Lymington" },
  {
    code: "01591",
    area: "Llanwrtyd Wells",
    description: "01591 - Llanwrtyd Wells",
  },
  { code: "01592", area: "Kirkcaldy", description: "01592 - Kirkcaldy" },
  { code: "01593", area: "Lybster", description: "01593 - Lybster" },
  { code: "01594", area: "Lydney", description: "01594 - Lydney" },
  { code: "01595", area: "Lerwick", description: "01595 - Lerwick" },
  { code: "0159575", area: "Foula", description: "0159575 - Foula" },
  { code: "0159576", area: "Fair Isle", description: "0159576 - Fair Isle" },
  {
    code: "01597",
    area: "Llandrindod Wells",
    description: "01597 - Llandrindod Wells",
  },
  { code: "01598", area: "Lynton", description: "01598 - Lynton" },
  { code: "01599", area: "Kyle", description: "01599 - Kyle" },
  { code: "01600", area: "Monmouth", description: "01600 - Monmouth" },
  { code: "01603", area: "Norwich", description: "01603 - Norwich" },
  { code: "01604", area: "Northampton", description: "01604 - Northampton" },
  { code: "01606", area: "Northwich", description: "01606 - Northwich" },
  {
    code: "01608",
    area: "Chipping Norton",
    description: "01608 - Chipping Norton",
  },
  {
    code: "01609",
    area: "Northallerton",
    description: "01609 - Northallerton",
  },
  { code: "0161", area: "Manchester", description: "0161 - Manchester" },
  {
    code: "01620",
    area: "North Berwick",
    description: "01620 - North Berwick",
  },
  { code: "01621", area: "Maldon", description: "01621 - Maldon" },
  { code: "01622", area: "Maidstone", description: "01622 - Maidstone" },
  { code: "01623", area: "Mansfield", description: "01623 - Mansfield" },
  { code: "01624", area: "Isle of Man", description: "01624 - Isle of Man" },
  { code: "01625", area: "Macclesfield", description: "01625 - Macclesfield" },
  { code: "01626", area: "Newton Abbot", description: "01626 - Newton Abbot" },
  { code: "01628", area: "Maidenhead", description: "01628 - Maidenhead" },
  { code: "01629", area: "Matlock", description: "01629 - Matlock" },
  {
    code: "01630",
    area: "Market Drayton",
    description: "01630 - Market Drayton",
  },
  { code: "01631", area: "Oban", description: "01631 - Oban" },
  { code: "01633", area: "Newport", description: "01633 - Newport" },
  { code: "01634", area: "Medway", description: "01634 - Medway" },
  { code: "01635", area: "Newbury", description: "01635 - Newbury" },
  { code: "01636", area: "Newark", description: "01636 - Newark" },
  { code: "01637", area: "Newquay", description: "01637 - Newquay" },
  { code: "01638", area: "Newmarket", description: "01638 - Newmarket" },
  { code: "01639", area: "Neath", description: "01639 - Neath" },
  { code: "01641", area: "Strathy", description: "01641 - Strathy" },
  {
    code: "01642",
    area: "Middlesbrough",
    description: "01642 - Middlesbrough",
  },
  { code: "01643", area: "Minehead", description: "01643 - Minehead" },
  { code: "01644", area: "New Galloway", description: "01644 - New Galloway" },
  {
    code: "01646",
    area: "Milford Haven",
    description: "01646 - Milford Haven",
  },
  {
    code: "01647",
    area: "Moretonhampstead",
    description: "01647 - Moretonhampstead",
  },
  { code: "01650", area: "Cemmaes Road", description: "01650 - Cemmaes Road" },
  { code: "01651", area: "Oldmeldrum", description: "01651 - Oldmeldrum" },
  { code: "01652", area: "Brigg", description: "01652 - Brigg" },
  { code: "01653", area: "Malton", description: "01653 - Malton" },
  { code: "01654", area: "Machynlleth", description: "01654 - Machynlleth" },
  { code: "01655", area: "Maybole", description: "01655 - Maybole" },
  { code: "01656", area: "Bridgend", description: "01656 - Bridgend" },
  { code: "01659", area: "Sanquhar", description: "01659 - Sanquhar" },
  { code: "01661", area: "Prudhoe", description: "01661 - Prudhoe" },
  { code: "01663", area: "New Mills", description: "01663 - New Mills" },
  {
    code: "01664",
    area: "Melton Mowbray",
    description: "01664 - Melton Mowbray",
  },
  { code: "01665", area: "Alnwick", description: "01665 - Alnwick" },
  { code: "01666", area: "Malmesbury", description: "01666 - Malmesbury" },
  { code: "01667", area: "Nairn", description: "01667 - Nairn" },
  { code: "01668", area: "Bamburgh", description: "01668 - Bamburgh" },
  { code: "01669", area: "Rothbury", description: "01669 - Rothbury" },
  { code: "01670", area: "Morpeth", description: "01670 - Morpeth" },
  {
    code: "01671",
    area: "Newton Stewart",
    description: "01671 - Newton Stewart",
  },
  { code: "01672", area: "Marlborough", description: "01672 - Marlborough" },
  { code: "01673", area: "Market Rasen", description: "01673 - Market Rasen" },
  { code: "01674", area: "Montrose", description: "01674 - Montrose" },
  { code: "01675", area: "Coleshill", description: "01675 - Coleshill" },
  { code: "01676", area: "Meriden", description: "01676 - Meriden" },
  { code: "01677", area: "Bedale", description: "01677 - Bedale" },
  { code: "01678", area: "Bala", description: "01678 - Bala" },
  {
    code: "01680",
    area: "Isle of Mull - Craignure",
    description: "01680 - Isle of Mull - Craignure",
  },
  {
    code: "01681",
    area: "Isle of Mull - Fionnphort",
    description: "01681 - Isle of Mull - Fionnphort",
  },
  { code: "01683", area: "Moffat", description: "01683 - Moffat" },
  { code: "01684", area: "Malvern", description: "01684 - Malvern" },
  {
    code: "01685",
    area: "Merthyr Tydfil",
    description: "01685 - Merthyr Tydfil",
  },
  { code: "016862", area: "Llanidloes", description: "016862 - Llanidloes" },
  { code: "016863", area: "Llanidloes", description: "016863 - Llanidloes" },
  { code: "016864", area: "Llanidloes", description: "016864 - Llanidloes" },
  { code: "016865", area: "Newtown", description: "016865 - Newtown" },
  { code: "016866", area: "Newtown", description: "016866 - Newtown" },
  { code: "016867", area: "Llanidloes", description: "016867 - Llanidloes" },
  { code: "016868", area: "Newtown", description: "016868 - Newtown" },
  { code: "016869", area: "Newtown", description: "016869 - Newtown" },
  { code: "01687", area: "Mallaig", description: "01687 - Mallaig" },
  {
    code: "01688",
    area: "Isle of Mull - Tobermory",
    description: "01688 - Isle of Mull - Tobermory",
  },
  { code: "01689", area: "Orpington", description: "01689 - Orpington" },
  { code: "01690", area: "Betws-y-Coed", description: "01690 - Betws-y-Coed" },
  { code: "01691", area: "Oswestry", description: "01691 - Oswestry" },
  {
    code: "01692",
    area: "North Walsham",
    description: "01692 - North Walsham",
  },
  {
    code: "01694",
    area: "Church Stretton",
    description: "01694 - Church Stretton",
  },
  { code: "01695", area: "Skelmersdale", description: "01695 - Skelmersdale" },
  { code: "01697", area: "Brampton", description: "01697 - Brampton" },
  { code: "016973", area: "Wigton", description: "016973 - Wigton" },
  {
    code: "016974",
    area: "Raughton Head",
    description: "016974 - Raughton Head",
  },
  { code: "01698", area: "Motherwell", description: "01698 - Motherwell" },
  { code: "01700", area: "Rothesay", description: "01700 - Rothesay" },
  {
    code: "01702",
    area: "Southend-on-Sea",
    description: "01702 - Southend-on-Sea",
  },
  { code: "01704", area: "Southport", description: "01704 - Southport" },
  { code: "01706", area: "Rochdale", description: "01706 - Rochdale" },
  {
    code: "01707",
    area: "Welwyn Garden City",
    description: "01707 - Welwyn Garden City",
  },
  { code: "01708", area: "Romford", description: "01708 - Romford" },
  { code: "01709", area: "Rotherham", description: "01709 - Rotherham" },
  {
    code: "01720",
    area: "Isles of Scilly",
    description: "01720 - Isles of Scilly",
  },
  { code: "01721", area: "Peebles", description: "01721 - Peebles" },
  { code: "01722", area: "Salisbury", description: "01722 - Salisbury" },
  { code: "01723", area: "Scarborough", description: "01723 - Scarborough" },
  { code: "01724", area: "Scunthorpe", description: "01724 - Scunthorpe" },
  { code: "01725", area: "Rockbourne", description: "01725 - Rockbourne" },
  { code: "01726", area: "St Austell", description: "01726 - St Austell" },
  { code: "01727", area: "St Albans", description: "01727 - St Albans" },
  { code: "01728", area: "Saxmundham", description: "01728 - Saxmundham" },
  { code: "01729", area: "Settle", description: "01729 - Settle" },
  { code: "01730", area: "Petersfield", description: "01730 - Petersfield" },
  { code: "01732", area: "Sevenoaks", description: "01732 - Sevenoaks" },
  { code: "01733", area: "Peterborough", description: "01733 - Peterborough" },
  { code: "01736", area: "Penzance", description: "01736 - Penzance" },
  { code: "01737", area: "Redhill", description: "01737 - Redhill" },
  { code: "01738", area: "Perth", description: "01738 - Perth" },
  { code: "01740", area: "Sedgefield", description: "01740 - Sedgefield" },
  { code: "01743", area: "Shrewsbury", description: "01743 - Shrewsbury" },
  { code: "01744", area: "St Helens", description: "01744 - St Helens" },
  { code: "01745", area: "Rhyl", description: "01745 - Rhyl" },
  { code: "01746", area: "Bridgnorth", description: "01746 - Bridgnorth" },
  { code: "01747", area: "Shaftesbury", description: "01747 - Shaftesbury" },
  { code: "01748", area: "Richmond", description: "01748 - Richmond" },
  {
    code: "01749",
    area: "Shepton Mallet",
    description: "01749 - Shepton Mallet",
  },
  { code: "01750", area: "Selkirk", description: "01750 - Selkirk" },
  { code: "01751", area: "Pickering", description: "01751 - Pickering" },
  { code: "01752", area: "Plymouth", description: "01752 - Plymouth" },
  { code: "01753", area: "Slough", description: "01753 - Slough" },
  { code: "01754", area: "Skegness", description: "01754 - Skegness" },
  { code: "01756", area: "Skipton", description: "01756 - Skipton" },
  { code: "01757", area: "Selby", description: "01757 - Selby" },
  { code: "01758", area: "Pwllheli", description: "01758 - Pwllheli" },
  { code: "01759", area: "Pocklington", description: "01759 - Pocklington" },
  { code: "01760", area: "Swaffham", description: "01760 - Swaffham" },
  { code: "01761", area: "Temple Cloud", description: "01761 - Temple Cloud" },
  { code: "01763", area: "Royston", description: "01763 - Royston" },
  { code: "01764", area: "Crieff", description: "01764 - Crieff" },
  { code: "01765", area: "Ripon", description: "01765 - Ripon" },
  { code: "01766", area: "Porthmadog", description: "01766 - Porthmadog" },
  { code: "01767", area: "Sandy", description: "01767 - Sandy" },
  { code: "01768", area: "Penrith", description: "01768 - Penrith" },
  { code: "017683", area: "Appleby", description: "017683 - Appleby" },
  {
    code: "017684",
    area: "Pooley Bridge",
    description: "017684 - Pooley Bridge",
  },
  { code: "017687", area: "Keswick", description: "017687 - Keswick" },
  { code: "01769", area: "South Molton", description: "01769 - South Molton" },
  {
    code: "01770",
    area: "Isle of Arran",
    description: "01770 - Isle of Arran",
  },
  { code: "01771", area: "Maud", description: "01771 - Maud" },
  { code: "01772", area: "Preston", description: "01772 - Preston" },
  { code: "01773", area: "Ripley", description: "01773 - Ripley" },
  { code: "01775", area: "Spalding", description: "01775 - Spalding" },
  { code: "01776", area: "Stranraer", description: "01776 - Stranraer" },
  { code: "01777", area: "Retford", description: "01777 - Retford" },
  { code: "01778", area: "Bourne", description: "01778 - Bourne" },
  { code: "01779", area: "Peterhead", description: "01779 - Peterhead" },
  { code: "01780", area: "Stamford", description: "01780 - Stamford" },
  {
    code: "01782",
    area: "Stoke-on-Trent",
    description: "01782 - Stoke-on-Trent",
  },
  { code: "01784", area: "Staines", description: "01784 - Staines" },
  { code: "01785", area: "Stafford", description: "01785 - Stafford" },
  { code: "01786", area: "Stirling", description: "01786 - Stirling" },
  { code: "01787", area: "Sudbury", description: "01787 - Sudbury" },
  { code: "01788", area: "Rugby", description: "01788 - Rugby" },
  {
    code: "01789",
    area: "Stratford-upon-Avon",
    description: "01789 - Stratford-upon-Avon",
  },
  { code: "01790", area: "Spilsby", description: "01790 - Spilsby" },
  { code: "01792", area: "Swansea", description: "01792 - Swansea" },
  { code: "01793", area: "Swindon", description: "01793 - Swindon" },
  { code: "01794", area: "Romsey", description: "01794 - Romsey" },
  {
    code: "01795",
    area: "Sittingbourne",
    description: "01795 - Sittingbourne",
  },
  { code: "01796", area: "Pitlochry", description: "01796 - Pitlochry" },
  { code: "01797", area: "Rye", description: "01797 - Rye" },
  { code: "01798", area: "Pulborough", description: "01798 - Pulborough" },
  {
    code: "01799",
    area: "Saffron Walden",
    description: "01799 - Saffron Walden",
  },
  { code: "01803", area: "Torquay", description: "01803 - Torquay" },
  { code: "01805", area: "Torrington", description: "01805 - Torrington" },
  { code: "01806", area: "Shetland", description: "01806 - Shetland" },
  {
    code: "01807",
    area: "Ballindalloch",
    description: "01807 - Ballindalloch",
  },
  { code: "01808", area: "Tomatin", description: "01808 - Tomatin" },
  { code: "01809", area: "Tomdoun", description: "01809 - Tomdoun" },
  { code: "01821", area: "Kinrossie", description: "01821 - Kinrossie" },
  { code: "01822", area: "Tavistock", description: "01822 - Tavistock" },
  { code: "01823", area: "Taunton", description: "01823 - Taunton" },
  { code: "01824", area: "Ruthin", description: "01824 - Ruthin" },
  { code: "01825", area: "Uckfield", description: "01825 - Uckfield" },
  { code: "01827", area: "Tamworth", description: "01827 - Tamworth" },
  { code: "01828", area: "Coupar Angus", description: "01828 - Coupar Angus" },
  { code: "01829", area: "Tarporley", description: "01829 - Tarporley" },
  {
    code: "01830",
    area: "Kirkwhelpington",
    description: "01830 - Kirkwhelpington",
  },
  { code: "01832", area: "Clopton", description: "01832 - Clopton" },
  {
    code: "01833",
    area: "Barnard Castle",
    description: "01833 - Barnard Castle",
  },
  { code: "01834", area: "Narberth", description: "01834 - Narberth" },
  { code: "01835", area: "St Boswells", description: "01835 - St Boswells" },
  { code: "01837", area: "Okehampton", description: "01837 - Okehampton" },
  { code: "01838", area: "Dalmally", description: "01838 - Dalmally" },
  { code: "01840", area: "Camelford", description: "01840 - Camelford" },
  { code: "01841", area: "Newquay", description: "01841 - Newquay" },
  { code: "01842", area: "Thetford", description: "01842 - Thetford" },
  { code: "01843", area: "Thanet", description: "01843 - Thanet" },
  { code: "01844", area: "Thame", description: "01844 - Thame" },
  { code: "01845", area: "Thirsk", description: "01845 - Thirsk" },
  { code: "018472", area: "Thurso", description: "018472 - Thurso" },
  { code: "018473", area: "Thurso", description: "018473 - Thurso" },
  { code: "018474", area: "Thurso", description: "018474 - Thurso" },
  { code: "018475", area: "Thurso", description: "018475 - Thurso" },
  { code: "018476", area: "Tongue", description: "018476 - Tongue" },
  { code: "018477", area: "Tongue", description: "018477 - Tongue" },
  { code: "018478", area: "Thurso", description: "018478 - Thurso" },
  { code: "018479", area: "Tongue", description: "018479 - Tongue" },
  { code: "01848", area: "Thornhill", description: "01848 - Thornhill" },
  { code: "018512", area: "Stornoway", description: "018512 - Stornoway" },
  { code: "018513", area: "Stornoway", description: "018513 - Stornoway" },
  {
    code: "018514",
    area: "Great Bernera",
    description: "018514 - Great Bernera",
  },
  { code: "018515", area: "Stornoway", description: "018515 - Stornoway" },
  {
    code: "018516",
    area: "Great Bernera",
    description: "018516 - Great Bernera",
  },
  { code: "018517", area: "Stornoway", description: "018517 - Stornoway" },
  { code: "018518", area: "Stornoway", description: "018518 - Stornoway" },
  {
    code: "018519",
    area: "Great Bernera",
    description: "018519 - Great Bernera",
  },
  { code: "01852", area: "Kilmelford", description: "01852 - Kilmelford" },
  { code: "01854", area: "Ullapool", description: "01854 - Ullapool" },
  { code: "01855", area: "Ballachulish", description: "01855 - Ballachulish" },
  { code: "01856", area: "Orkney", description: "01856 - Orkney" },
  { code: "01857", area: "Sanday", description: "01857 - Sanday" },
  {
    code: "01858",
    area: "Market Harborough",
    description: "01858 - Market Harborough",
  },
  { code: "01859", area: "Harris", description: "01859 - Harris" },
  { code: "01862", area: "Tain", description: "01862 - Tain" },
  { code: "01863", area: "Ardgay", description: "01863 - Ardgay" },
  { code: "01864", area: "Abington", description: "01864 - Abington" },
  { code: "01865", area: "Oxford", description: "01865 - Oxford" },
  { code: "01866", area: "Kilchrenan", description: "01866 - Kilchrenan" },
  { code: "01869", area: "Bicester", description: "01869 - Bicester" },
  {
    code: "01870",
    area: "Isle of Benbecula",
    description: "01870 - Isle of Benbecula",
  },
  { code: "01871", area: "Castlebay", description: "01871 - Castlebay" },
  { code: "01872", area: "Truro", description: "01872 - Truro" },
  { code: "01873", area: "Abergavenny", description: "01873 - Abergavenny" },
  { code: "01874", area: "Brecon", description: "01874 - Brecon" },
  { code: "01875", area: "Tranent", description: "01875 - Tranent" },
  { code: "01876", area: "Lochmaddy", description: "01876 - Lochmaddy" },
  { code: "01877", area: "Callander", description: "01877 - Callander" },
  { code: "01878", area: "Lochboisdale", description: "01878 - Lochboisdale" },
  { code: "01879", area: "Scarinish", description: "01879 - Scarinish" },
  { code: "01880", area: "Tarbert", description: "01880 - Tarbert" },
  {
    code: "01882",
    area: "Kinloch Rannoch",
    description: "01882 - Kinloch Rannoch",
  },
  { code: "01883", area: "Caterham", description: "01883 - Caterham" },
  { code: "01884", area: "Tiverton", description: "01884 - Tiverton" },
  { code: "01885", area: "Pencombe", description: "01885 - Pencombe" },
  { code: "01886", area: "Bromyard", description: "01886 - Bromyard" },
  { code: "01887", area: "Aberfeldy", description: "01887 - Aberfeldy" },
  { code: "01888", area: "Turriff", description: "01888 - Turriff" },
  { code: "01889", area: "Rugeley", description: "01889 - Rugeley" },
  { code: "018902", area: "Coldstream", description: "018902 - Coldstream" },
  { code: "018903", area: "Coldstream", description: "018903 - Coldstream" },
  { code: "018904", area: "Coldstream", description: "018904 - Coldstream" },
  { code: "018905", area: "Ayton", description: "018905 - Ayton" },
  { code: "018906", area: "Ayton", description: "018906 - Ayton" },
  { code: "018907", area: "Ayton", description: "018907 - Ayton" },
  { code: "018908", area: "Coldstream", description: "018908 - Coldstream" },
  { code: "018909", area: "Ayton", description: "018909 - Ayton" },
  {
    code: "01892",
    area: "Tunbridge Wells",
    description: "01892 - Tunbridge Wells",
  },
  { code: "01895", area: "Uxbridge", description: "01895 - Uxbridge" },
  { code: "01896", area: "Galashiels", description: "01896 - Galashiels" },
  { code: "01899", area: "Biggar", description: "01899 - Biggar" },
  { code: "01900", area: "Workington", description: "01900 - Workington" },
  {
    code: "01902",
    area: "Wolverhampton",
    description: "01902 - Wolverhampton",
  },
  { code: "01903", area: "Worthing", description: "01903 - Worthing" },
  { code: "01904", area: "York", description: "01904 - York" },
  { code: "01905", area: "Worcester", description: "01905 - Worcester" },
  {
    code: "01908",
    area: "Milton Keynes",
    description: "01908 - Milton Keynes",
  },
  { code: "01909", area: "Worksop", description: "01909 - Worksop" },
  { code: "01912", area: "Tyneside", description: "01912 - Tyneside" },
  { code: "01913", area: "Durham", description: "01913 - Durham" },
  { code: "01914", area: "Tyneside", description: "01914 - Tyneside" },
  { code: "01915", area: "Sunderland", description: "01915 - Sunderland" },
  { code: "01916", area: "Tyneside", description: "01916 - Tyneside" },
  { code: "01917", area: "Sunderland", description: "01917 - Sunderland" },
  { code: "01918", area: "Tyneside", description: "01918 - Tyneside" },
  { code: "01919", area: "Durham", description: "01919 - Durham" },
  { code: "01920", area: "Ware", description: "01920 - Ware" },
  { code: "01922", area: "Walsall", description: "01922 - Walsall" },
  { code: "01923", area: "Watford", description: "01923 - Watford" },
  { code: "01924", area: "Wakefield", description: "01924 - Wakefield" },
  { code: "01925", area: "Warrington", description: "01925 - Warrington" },
  { code: "01926", area: "Warwick", description: "01926 - Warwick" },
  { code: "01928", area: "Runcorn", description: "01928 - Runcorn" },
  { code: "01929", area: "Wareham", description: "01929 - Wareham" },
  { code: "01931", area: "Shap", description: "01931 - Shap" },
  { code: "01932", area: "Weybridge", description: "01932 - Weybridge" },
  {
    code: "01933",
    area: "Wellingborough",
    description: "01933 - Wellingborough",
  },
  {
    code: "01934",
    area: "Weston-super-Mare",
    description: "01934 - Weston-super-Mare",
  },
  { code: "01935", area: "Yeovil", description: "01935 - Yeovil" },
  { code: "01937", area: "Wetherby", description: "01937 - Wetherby" },
  { code: "01938", area: "Welshpool", description: "01938 - Welshpool" },
  { code: "01939", area: "Wem", description: "01939 - Wem" },
  { code: "01942", area: "Wigan", description: "01942 - Wigan" },
  { code: "01943", area: "Guiseley", description: "01943 - Guiseley" },
  {
    code: "01944",
    area: "West Heslerton",
    description: "01944 - West Heslerton",
  },
  { code: "01945", area: "Wisbech", description: "01945 - Wisbech" },
  { code: "01946", area: "Whitehaven", description: "01946 - Whitehaven" },
  { code: "01947", area: "Whitby", description: "01947 - Whitby" },
  { code: "01948", area: "Whitchurch", description: "01948 - Whitchurch" },
  { code: "01949", area: "Whatton", description: "01949 - Whatton" },
  { code: "01950", area: "Sandwick", description: "01950 - Sandwick" },
  { code: "01951", area: "Colonsay", description: "01951 - Colonsay" },
  { code: "01952", area: "Telford", description: "01952 - Telford" },
  { code: "01953", area: "Wymondham", description: "01953 - Wymondham" },
  { code: "01954", area: "Madingley", description: "01954 - Madingley" },
  { code: "01955", area: "Wick", description: "01955 - Wick" },
  { code: "01957", area: "Mid Yell", description: "01957 - Mid Yell" },
  { code: "01959", area: "Westerham", description: "01959 - Westerham" },
  { code: "01962", area: "Winchester", description: "01962 - Winchester" },
  { code: "01963", area: "Wincanton", description: "01963 - Wincanton" },
  { code: "01964", area: "Hornsea", description: "01964 - Hornsea" },
  { code: "01964", area: "Withernsea", description: "01964 - Withernsea" },
  { code: "01967", area: "Strontian", description: "01967 - Strontian" },
  { code: "01968", area: "Penicuik", description: "01968 - Penicuik" },
  { code: "01969", area: "Leyburn", description: "01969 - Leyburn" },
  { code: "01970", area: "Aberystwyth", description: "01970 - Aberystwyth" },
  {
    code: "01970",
    area: "Talybont (Aberystwyth)",
    description: "01970 - Talybont (Aberystwyth)",
  },
  { code: "01971", area: "Scourie", description: "01971 - Scourie" },
  {
    code: "01972",
    area: "Glenborrodale",
    description: "01972 - Glenborrodale",
  },
  { code: "01974", area: "Llanon", description: "01974 - Llanon" },
  { code: "01975", area: "Alford", description: "01975 - Alford" },
  { code: "01977", area: "Pontefract", description: "01977 - Pontefract" },
  { code: "01978", area: "Wrexham", description: "01978 - Wrexham" },
  { code: "01980", area: "Amesbury", description: "01980 - Amesbury" },
  { code: "01981", area: "Wormbridge", description: "01981 - Wormbridge" },
  { code: "01982", area: "Builth Wells", description: "01982 - Builth Wells" },
  {
    code: "01983",
    area: "Isle of Wight",
    description: "01983 - Isle of Wight",
  },
  { code: "01984", area: "Watchet", description: "01984 - Watchet" },
  { code: "01985", area: "Warminster", description: "01985 - Warminster" },
  { code: "01986", area: "Bungay", description: "01986 - Bungay" },
  { code: "01987", area: "Ebbsfleet", description: "01987 - Ebbsfleet" },
  { code: "01988", area: "Wigtown", description: "01988 - Wigtown" },
  { code: "01989", area: "Ross-on-Wye", description: "01989 - Ross-on-Wye" },
  { code: "01992", area: "Lea Valley", description: "01992 - Lea Valley" },
  { code: "01993", area: "Witney", description: "01993 - Witney" },
  { code: "01994", area: "St Clears", description: "01994 - St Clears" },
  { code: "01995", area: "Garstang", description: "01995 - Garstang" },
  { code: "01997", area: "Strathpeffer", description: "01997 - Strathpeffer" },
  { code: "0207", area: "London", description: "0207 - London" },
  { code: "02381", area: "Southampton", description: "02381 - Southampton" },
  { code: "02393", area: "Portsmouth", description: "02393 - Portsmouth" },
  { code: "02477", area: "Coventry", description: "02477 - Coventry" },
  { code: "02895", area: "Belfast", description: "02895 - Belfast" },
  { code: "02921", area: "Cardiff", description: "02921 - Cardiff" },
  {
    code: "0843",
    area: "Non Geographic",
    description: "0843 - Non Geographic",
  },
  {
    code: "0844",
    area: "Non Geographic",
    description: "0844 - Non Geographic",
  },
  {
    code: "0845",
    area: "Non Geographic",
    description: "0845 - Non Geographic",
  },
].sort((a, b) => {
  if (a.area < b.area) return -1;
  if (a.area > b.area) return 1;

  return 0;
});

export const numbersTemplate = {
  defaultLocalPrefix: "0161",
  localPrefixes: localPrefixes,
  localCategories: ["Silver", "Gold", "Platinum"],
  defaultNationalPrefix: "0333",
  nationalPrefixes: [
    { code: "0300", description: "0300 - Charity/Non Profit" },
    { code: "0303", description: "0303 - Charity/Non Profit" },
    { code: "0330", description: "0330" },
    { code: "0333", description: "0333" },
  ],
  nationalCategories: ["Standard", "Gold", "Platinum"],
  defaultFreephonePrefix: "0800",
  freephonePrefixes: [{ code: "0800", description: "0800" }],
  freephoneCategories: ["Gold", "Platinum"],
};
