

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class HttpService {

  get apiUrl(): string {
    return environment.apiUrl;
  }

  constructor(private http: HttpClient) {}

  get(endpoint: string, options?): Observable<any> {
    return this.http.get(`${this.apiUrl}/${endpoint}`, this.populateOptionDefaults(options));
  }

  getCustom(endpoint: string, options?): Observable<any> {
    return this.http.get(`${endpoint}`, this.populateOptionDefaults(options));
  }

  post(endpoint: string, data, options?): Observable<any> {
    
    let headers = new HttpHeaders();
    headers.append('X-test', 'test');

    return this.http.post(`${this.apiUrl}/${endpoint}`, data, this.populateOptionDefaults(options));
  }

  put(endpoint: string, data, options?): Observable<any> {
    return this.http.put(`${this.apiUrl}/${endpoint}`, data, this.populateOptionDefaults(options));
  }

  delete(endpoint: string, options?): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${endpoint}`, this.populateOptionDefaults(options));
  }

  populateOptionDefaults(options: any) {
    // let headers = new HttpHeaders();
    // headers.set('X-reseller-code', environment.resellerCode);

    // if (!options)
    //   return { headers: headers };
    
    // if (options.headers) {
    //   options.headers.append('X-reseller-cdoe', environment.resellerCode);
    //   return options;
    // }
        
    // options.headers = headers; 
    return options;
  }
}
