import { ChangeDetectionStrategy, Component, } from '@angular/core';

@Component({
  selector: 'r247-marketing-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class R247MarketingHeaderComponent {
  constructor() {}
}