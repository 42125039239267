import { environment } from "../../environments/environment";

export function getMode(): string {
  if (environment.apiUrl.includes("localhost")) return MODE_DEVELOPMENT;

  var apiUrl = new URL(environment.apiUrl);

  switch (apiUrl.host) {
    case "api-staging.callcentre.services":
      return MODE_STAGING;
    case "api-preprod.callcentre.services":
      return MODE_PRE_PRODUCTION;
    case "api.callcentre.services":
      return MODE_LIVE;
    default:
      return MODE_UNKNOWN;
  }
}

export const MODE_DEVELOPMENT = "development";
export const MODE_STAGING = "staging";
export const MODE_PRE_PRODUCTION = "pre-production";
export const MODE_LIVE = "live";
export const MODE_UNKNOWN = "unknown";
