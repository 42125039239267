import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  signUpForm,
  Reception,
  Contact,
  signUpResult,
  Payment,
} from "../../../@core/interfaces/common/signUp";
import { SignUpService } from "../../../@core/backend/receptionist/services/sign-up-service";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "r247-register",
  styleUrls: ["./register.component.scss"],
  templateUrl: "./register.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247RegisterComponent implements OnInit {
  AWAITING_INPUT = "awaiting-input";
  ERROR_CHECK_FAIL = "error-check-fail";
  UNKNOWN_ERROR = "unknown-error";
  PROCESSING = "processing";
  SUCCESS = "success";
  CREATE_ACCOUNT_FAIL = "create-account-fail";
  NUMBER_PURCHASE_FAIL = "purchase-number-fail";
  PAYMENT_FAIL = "payment-fail";
  ACTIVATION_FAIL = "activation-fail";

  constructor(
    private signUpService: SignUpService,
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.number.category = this.route.snapshot.queryParamMap.get("category");
    this.number.prefix = this.route.snapshot.queryParamMap.get("prefix");
    this.number.range = this.route.snapshot.queryParamMap.get("range");
    this.number.number = this.route.snapshot.queryParamMap.get("number");
  }

  hasCompanyAddress = false;
  submitted = false;
  errors: string[] = [];
  errorMessage = "";
  isRectifiableError = false;
  messages: string[] = [];
  user: any = {};
  clientId = 0;
  userLocation: string;
  currentScreenIndex = 1;

  minimumTopUp = 40;

  receptionDataCopied = false;
  paymentDataCopied = false;

  billingAddress: any = {};
  account: any = {};
  company: any = {};
  reception: Reception = new Reception();
  receptionContacts: Contact[] = [];
  payment: Payment = new Payment();

  registrationState = this.AWAITING_INPUT;

  number = {
    category: "",
    prefix: "",
    range: "",
    number: "",
  };

  onAccountStepComplete(data) {
    this.account.usersName = data.name;
    this.account.userEmail = data.email;
    this.account.userPassword = data.password;
    this.account.confirmPassword = data.confirmPassword;

    this.company.name = data.clientName;
    this.company.phoneNo = data.clientContactNumber;
    this.company.addressLine1 = data.addressLine1;
    this.company.addressLine2 = data.addressLine2;
    this.company.addressLine3 = data.addressLine3;
    this.company.postCode = data.postCode;
    this.company.city = data.city;
    this.company.county = data.county;
    this.company.country = data.country;

    this.hasCompanyAddress = true;

    if (!this.receptionDataCopied) this.copyClientInfoToReception(data);

    if (!this.paymentDataCopied) {
      this.updateBillingAddress(data);
      this.payment.cardHolderName = data.name;
      this.paymentDataCopied = true;
    }

    this.nextStep();
  }

  copyClientInfoToReception(data) {
    var companyName = data.clientName;

    if (companyName.toLowerCase().endsWith(" ltd"))
      companyName = companyName.substring(0, companyName.length - 4);

    this.reception.greeting = companyName;
    (this.reception.phoneNo = data.clientContactNumber),
      (this.reception.email = data.email);

    var generalContact = new Contact();
    generalContact.email = data.email;
    generalContact.name = "General";
    generalContact.sendEmail = true;
    generalContact.sendSms = false;

    this.reception.contacts.push(generalContact);

    this.receptionDataCopied = true;
  }

  updateBillingAddress(data: any) {
    this.billingAddress = {
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      addressLine3: data.addressLine3,
      city: data.city,
      county: data.county,
      country: data.country,
      postCode: data.postCode.toUpperCase(),
    };
  }

  onReceptionStepPrevious(reception: Reception) {
    this.reception = reception;
    this.previousStep();
  }

  onReceptionStepComplete(reception: Reception) {
    this.reception = reception;
    this.nextStep();
  }

  onPaymentStepPrevious(payment: any) {
    this.payment = payment.paymentDetails;
    this.billingAddress = payment.billingAddress;
    this.previousStep();
  }

  onPaymentStepComplete(payment: any) {
    this.payment = payment.paymentDetails;
    this.updateBillingAddress(payment.billingAddress);
    this.register();
  }

  previousStep() {
    // hack to hide terms and conidtions until they've been written
    if (this.currentScreenIndex === 4) {
      this.currentScreenIndex = this.currentScreenIndex - 2;
      return;
    }

    this.currentScreenIndex = this.currentScreenIndex - 1;
  }

  nextStep() {
    // hack to hide terms and conidtions until they've been written
    if (this.currentScreenIndex === 2) {
      this.currentScreenIndex = this.currentScreenIndex + 2;
      window.scroll(0, 0);
      return;
    }

    this.currentScreenIndex = this.currentScreenIndex + 1;

    window.scroll(0, 0);
  }

  register(): void {
    this.errors = this.messages = [];
    this.submitted = true;

    this.registrationState = this.PROCESSING;

    this.signUpService.signUp(this.getRegistrationFormData()).subscribe(
      (result: signUpResult) => {
        this.submitted = false;

        this.registrationState = result?.status;

        this.cd.detectChanges();
      },
      (error) => this.onRegisterError(error)
    );
  }

  onRegisterError(error: any) {
    this.clientId = error.error.clientId;

    if (error.error && error.error.status) {
      this.registrationState = error.error.status;
      this.isRectifiableError = error.error.isRectifiableError;
      this.errorMessage = error.error.errorMessage;
    } else {
      this.registrationState = this.UNKNOWN_ERROR;
      this.isRectifiableError = false;
      this.errorMessage = "An unknown error occured";
    }
  }

  repurchaseNumberSuccess(purchasedNumber: any) {
    this.registrationState = this.SUCCESS;
    this.number.category = purchasedNumber.category;
    this.number.prefix = purchasedNumber.prefix;
    this.number.range = purchasedNumber.range;
    this.number.number = purchasedNumber.number;
  }

  getRegistrationFormData(): signUpForm {
    let signUpData = new signUpForm();

    let resellerId = this.route.snapshot.queryParamMap.get("reseller");
    signUpData.resellerId = resellerId == null ? 0 : Number(resellerId);
    signUpData.franchiseCode = environment.franchiseCode;

    signUpData.user.fullName = this.account.usersName;
    signUpData.user.email = this.account.userEmail;
    signUpData.user.password = this.account.userPassword;

    signUpData.client.name = this.company.name;
    signUpData.client.emailAddress = this.account.userEmail;
    signUpData.client.phoneNumber = this.company.phoneNo;
    signUpData.client.companyAddressLine1 = this.company.addressLine1;
    signUpData.client.companyAddressLine2 = this.company.addressLine2;
    signUpData.client.companyAddressLine3 = this.company.addressLine3;
    signUpData.client.companyPostCode = this.company.postCode;
    signUpData.client.companyCity = this.company.city;
    signUpData.client.companyCounty = this.company.county;
    signUpData.client.companyCountry = this.company.country;
    signUpData.client.billingAddressLine1 = this.billingAddress.addressLine1;
    signUpData.client.billingAddressLine2 = this.billingAddress.addressLine2;
    signUpData.client.billingAddressLine3 = this.billingAddress.addressLine3;
    signUpData.client.billingPostCode = this.billingAddress.postCode;
    signUpData.client.billingCity = this.billingAddress.city;
    signUpData.client.billingCounty = this.billingAddress.county;
    signUpData.client.billingCountry = this.billingAddress.country;

    let reception = new Reception();

    reception.description = this.reception.description;
    reception.openingHours = this.reception.openingHours;
    reception.phoneNo = this.reception.phoneNo;
    reception.website = this.reception.website;
    reception.email = this.reception.email;
    reception.numberPlan = this.number.category;
    reception.virtualNumberPrefix = this.number.prefix;
    reception.virtualNumberRange = this.number.range;
    reception.virtualNumberNumber = this.number.number;
    reception.shouldCollectCompanyName =
      this.reception.shouldCollectCompanyName;
    reception.greeting = this.reception.greeting;

    reception.contacts = this.reception.contacts;

    signUpData.client.receptions = [reception];

    signUpData.payment.amount = this.payment.amount;
    signUpData.payment.createAutoTopUp = this.payment.createAutoTopUp;
    signUpData.payment.saveCardDetails = this.payment.saveCardDetails;
    signUpData.payment.cardDescription = this.payment.cardDescription;
    signUpData.payment.cardHolderName = this.payment.cardHolderName;
    signUpData.payment.cardNumber = this.payment.cardNumber;
    signUpData.payment.cvc = this.payment.cvc;
    signUpData.payment.expiryMonth = this.payment.expiryMonth;
    signUpData.payment.expiryYear = this.payment.expiryYear;

    return signUpData;
  }
}
