<div class="row mb-1">
  <div *ngIf="isNumberChosen === false" class="col-12 text-center">
    <h5>{{chooseNumberCaption}}</h5>
  </div>
</div>
<r247-desktop-number-chooser 
  [localNumbers]="localNumbers" 
  [nationalNumbers]="nationalNumbers"
  [freephoneNumbers]="freephoneNumbers"
  [searchingLocalNumbers]="searchingLocalNumbers"
  [searchingNationalNumbers]="searchingNationalNumbers"
  [searchingFreephoneNumbers]="searchingFreephoneNumbers"
  [selectedLocalCategory]="selectedLocalCategory"
  [selectedNationalCategory]="selectedNationalCategory"
  [selectedFreephoneCategory]="selectedFreephoneCategory"
  (localNumbersSearched)="onLocalNumbersSearched($event)"
  (nationalNumbersSearched)="onNationalNumbersSearched($event)"
  (freephoneNumbersSearched)="onFreephoneNumbersSearched($event)"
  (numberConfirmed)="onNumberConfirmClick($event)"></r247-desktop-number-chooser>
<r247-mobile-number-chooser
  [localNumbers]="localNumbers" 
  [nationalNumbers]="nationalNumbers"
  [freephoneNumbers]="freephoneNumbers" 
  [searchingLocalNumbers]="searchingLocalNumbers"
  [searchingNationalNumbers]="searchingNationalNumbers"
  [searchingFreephoneNumbers]="searchingFreephoneNumbers"
  [selectedLocalCategory]="selectedLocalCategory"
  [selectedNationalCategory]="selectedNationalCategory"
  [selectedFreephoneCategory]="selectedFreephoneCategory"
  (localNumbersSearched)="onLocalNumbersSearched($event)"
  (nationalNumbersSearched)="onNationalNumbersSearched($event)"
  (freephoneNumbersSearched)="onFreephoneNumbersSearched($event)"
  (numberConfirmed)="onNumberConfirmClick($event)"></r247-mobile-number-chooser>