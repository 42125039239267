<div class="row mb-3">
    <div class="col-12">
      <h1>FAQs</h1>
    </div>
  </div>
  <div class="row mb-2">
      <div class="col-12">
          <h2>Reception Service</h2>
      </div>
  </div>
  <div class="row">
    <div class="col-12">
        <ngb-accordion [closeOthers]="false" #acc="ngbAccordion">
            <ngb-panel id="call-divert-question-accordian-panel">
              <ng-template ngbPanelTitle>
                <span class="accordian-title">Who will answer my calls?</span>
              </ng-template>
              <ng-template ngbPanelContent>
                <p>Your dedicated receptionist sits within a small team of highly trained receptionists based in our Peterborough Call Centre. We are a solely UK based service, providing 24/7 reception services in your company name. The team are friendly and professional, ensuring your customers feel as if they are speaking to a member of your team.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="choose-number-question-accordian-panel">
                <ng-template ngbPanelTitle>
                  <span class="accordian-title">How do I choose a phone number and how does it work?</span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p>247Receptionist offers a choice of any UK dial code or non-geographical phone number which become your companies own phone number. You are free to publish this number as your main business number or as an additional number [for specific marketing purposes for example].</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="existing-number-question-accordian-panel">
                <ng-template ngbPanelTitle>
                  <span class="accordian-title">I already have a phone number that my customers use, what happens then?</span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p>Most clients divert their existing business number on overflow as a safety net to catch the calls they had been missing. This is highly effective in maximising opportunity and prevents wasted advertising budget. The speed of messaging puts you in front of your customer before they have had the time to call your competitors.</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="missed-call-question-accordian-panel">
                <ng-template ngbPanelTitle>
                  <span class="accordian-title">How will I know that I have missed a call?</span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p>You can choose any combination of methods to receive and access message details. We send messages to you / your staff by text message, by email and via our online portal, where communication is stored permanently for retrieval at any time. The data includes the amount of time your receptionist has taken to represent your business on every call to aid reconciliation. All data are available for download.</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="whose-calling-question-accordian-panel">
                <ng-template ngbPanelTitle>
                  <span class="accordian-title">How will my receptionist know how to answer for you and what to say?</span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p>When your customer calls, your company screen will open for your receptionist. It contains all the information required to represent your business professionally. Your receptionist will use your customised greeting to answer your customer calls and will represent you professionally. Your customer will know they have reached your business and leave the caller confident that you will respond when you are able.</p>
                </ng-template>
              </ngb-panel>
          </ngb-accordion>
    </div>
  </div>
  <div class="row mt-3 mb-2">
        <div class="col-12">
            <h2>Account</h2>
        </div>
    </div>
    <div class="row">
  <div class="col-12">
      <ngb-accordion [closeOthers]="false" #acc="ngbAccordion">
            <ngb-panel id="registration-fee-question-accordian-panel">
              <ng-template ngbPanelTitle>
                <span class="accordian-title">Is there a fee to sign up or register?</span>
              </ng-template>
              <ng-template ngbPanelContent>
                <p>There are no sign up or registration fees. Simply pay the first month charge for your chosen number and purchase the credit you require.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="no-credit-question-accordian-panel">
              <ng-template ngbPanelTitle>
                <span class="accordian-title">What happens if I run out of credit?</span>
              </ng-template>
              <ng-template ngbPanelContent>
                <p>When your balance drops below £15 and you do not have auto top ups enabled, we will email you to let you know. If you do not top up and your balance drops below zero, your account will be suspended and we will stop handling your calls until a top up is made. After thirty days your account will be deactivated and your phone number handed back to the provider.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="cancel-question-accordian-panel">
              <ng-template ngbPanelTitle>
                <span class="accordian-title">Am I tied in to a long contract. How do I cancel if I want?</span>
              </ng-template>
              <ng-template ngbPanelContent>
                <p>Change in life and business circumstance is a constant. If for whatever reason the service is no longer required, simply cease to use it and not purchase any further credit, as there is no contract term to be tied in to. Your chosen phone number can be retained and used without use of the receptionist service, for as long as you require, by topping up credit for your phone number usage only.</p>
              </ng-template>
            </ngb-panel>
        </ngb-accordion>
  </div>
</div>