import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Contact, Reception } from "../../../@core/interfaces/common/signUp";
import {
  EMAIL_PATTERN,
  PHONE_NUMBER_PATTERN,
  URL_PATTERN,
} from "../../../@core/utils/constants";

@Component({
  selector: "r247-register-reception-screen",
  templateUrl: "./register-reception-screen.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class R247RegisterReceptionScreenComponent {
  constructor(private fb: FormBuilder) {}

  @Input() reception: Reception;

  @Output() previousStep = new EventEmitter();
  @Output() stepComplete = new EventEmitter();

  receptionStep: FormGroup;

  get receptionGreeting() {
    return this.receptionStep.get("greeting");
  }
  get receptionSettingShouldCollectCompanyName() {
    return this.receptionStep.get("company");
  }
  get receptionSettingDescription() {
    return this.receptionStep.get("description");
  }
  get receptionSettingCompanyPhoneNumber() {
    return this.receptionStep.get("phoneNumber");
  }
  get receptionSettingCompanyWebsite() {
    return this.receptionStep.get("website");
  }
  get receptionSettingOpeningHours() {
    return this.receptionStep.get("openingHours");
  }
  get receptionSettingCompanyOperatingEmail() {
    return this.receptionStep.get("email");
  }

  get receptionContactsForm() {
    return this.receptionStep.get("contacts") as FormArray;
  }

  ngOnInit(): void {
    this.receptionStep = this.fb.group({
      description: [
        this.reception.description,
        {
          validators: [Validators.required, Validators.maxLength(255)],
          updateOn: "blur",
        },
      ],
      greeting: [
        this.reception.greeting,
        {
          validators: [Validators.required, Validators.minLength(2)],
          updateOn: "blur",
        },
      ],
      company: [this.reception.shouldCollectCompanyName ? "yes" : "no"],
      email: [
        this.reception.email,
        {
          validators: [Validators.pattern(EMAIL_PATTERN)],
          updateOn: "blur",
        },
      ],
      phoneNumber: [
        this.reception.phoneNo,
        {
          validators: [
            Validators.required,
            Validators.pattern(PHONE_NUMBER_PATTERN),
          ],
          updateOn: "blur",
        },
      ],
      website: [
        this.reception.website,
        {
          validators: [Validators.pattern(URL_PATTERN)],
          updateOn: "blur",
        },
      ],
      openingHours: [
        this.reception.openingHours,
        {
          validators: Validators.required,
          updateOn: "blur",
        },
      ],
      contacts: this.fb.array([]),
    });

    for (let i = 0; i < this.reception.contacts.length; i++) {
      var contact = this.reception.contacts[i];

      this.newContact(contact);
    }
  }

  newContact(contact: any) {
    this.receptionContactsForm.push(
      this.fb.group({
        name: [
          contact.name,
          {
            validators: Validators.required,
            updateOn: "blur",
          },
        ],
        email: [
          contact.email,
          {
            validators: [Validators.pattern(EMAIL_PATTERN)],
            updateOn: "blur",
          },
        ],
        phoneNumber: [
          contact.phoneNo,
          {
            validators: [Validators.pattern(PHONE_NUMBER_PATTERN)],
            updateOn: "blur",
          },
        ],
        sendSms: [contact.sendSms],
        sendEmail: [contact.sendEmail],
      })
    );
  }

  addContact() {
    this.newContact({
      name: "",
      email: "",
      phoneNumber: "",
      sendSms: false,
      sendEmail: false,
    });
  }

  deleteContact(index: number) {
    this.receptionContactsForm.removeAt(index);
  }

  completeStep() {
    if (this.receptionStep.invalid) {
      this.receptionStep.markAllAsTouched();
      return;
    }

    this.stepComplete.emit(this.getReceptionData());
  }

  goToPreviousStep() {
    this.previousStep.emit(this.getReceptionData());
  }

  private getReceptionData(): Reception {
    var reception = new Reception();
    reception.description = this.receptionSettingDescription.value;
    reception.greeting = this.receptionGreeting.value;
    reception.shouldCollectCompanyName =
      this.receptionSettingShouldCollectCompanyName.value === "yes";
    reception.phoneNo = this.receptionSettingCompanyPhoneNumber.value;
    reception.website = this.receptionSettingCompanyWebsite.value;
    reception.openingHours = this.receptionSettingOpeningHours.value;
    reception.email = this.receptionSettingCompanyOperatingEmail.value;

    let contacts = [];

    for (let i = 0; i < this.receptionContactsForm.length; i++) {
      let contact = new Contact();

      let control = this.receptionContactsForm.controls[i];

      contact.email = control.get("email").value;
      contact.phoneNo = control.get("phoneNumber").value;
      contact.name = control.get("name").value;
      contact.sendEmail = control.get("sendEmail").value;
      contact.sendSms = control.get("sendSms").value;

      contacts.push(contact);
    }

    reception.contacts = contacts;

    return reception;
  }
}
